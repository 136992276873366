<template>
  <div class="homepage">
    <!--Customer Intro-->
    <div id="featured" class="hero white">
      <div class="ui segment blank">
        <div class="ui container">
          <div class="ui stackable two column grid">
            <div class="seven wide column">
              <h1 id="page-title">Connecting <br />Content Creators<br />and Destinations</h1>
              <div>
                <h3 id="page-subtitle">Multiply your marketing efforts with authentic user&#x2011;generated content created by regional content creators!</h3>
              </div>
              <a :href="globalGetLink('ForCommunities')" class="ui button primary float left" style="margin-top: 2em">For Destinations ></a>
              <a :href="globalGetLink('ForCreators')" class="ui button basic float left" style="margin-top: 2em">For Creators ></a>
            </div>
            <div class="nine wide column hide-on-mobile" id="shrpa-cover-photo" style="margin-bottom: 44em">
              <img style="float: right; border-radius: 5px; width: 1000px; position: absolute; left: 100px" :src="`${contentBaseUri}/images/misc/home/shrpa-main-photo3-mid.png`" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hero white home-section no-top-margin pale-teal-bg">
      <div class="ui segment blank" style="background: transparent">
        <div class="ui container">
          <div class="ui three column relaxed stackable grid">
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="heart large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Effortless Creator Visits</div>
                    <div class="sub header differentiator-text">
                      Simplify creator visits with Shrpa.<br />
                      Set up, coordinate, and manage the entire process effortlessly.
                    </div>
                  </h3>
                </div>
              </div>
            </div>
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="edit large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Engage Diverse Perspectives</div>
                    <div class="sub header differentiator-text">Discover your community through a new set of eyes and connect with travelers seeking genuine experiences.</div>
                  </h3>
                </div>
              </div>
            </div>
            <div class="column promo">
              <div class="promo-wrapper">
                <div class="icon">
                  <i class="comments large primary icon"></i>
                </div>
                <div>
                  <h3 class="ui header promo-header">
                    <div class="content">Marketing content for all channels</div>
                    <div class="sub header differentiator-text">Multiply your team's efforts with amazing photos, write-ups, and experiences for any marketing channel.</div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loader v-if="isLoading" class="recent-creator-visits__loader" />

    <div v-else>
      <div class="hero home-section green-section" style="margin-bottom: 3em; background: white">
        <div class="ui container" style="padding-bottom: 45px; margin-bottom: 0; border-bottom: 1px rgba(0, 0, 0, 0.1) solid">
          <div class="creators-header-group" style="width: 100%; flex-direction: column; align-items: stretch">
            <div style="margin-top: 28px; padding-top: 10px">
              <h1 class="global-h1 ui header" style="width: 100%; font-size: 35px; line-height: 38px; text-align: center">Recent Creator Visits</h1>
            </div>
          </div>
          <div style="margin-top: 10px">
            <p id="page-subtitle" style="color: rgba(0, 0, 0, 0.5); font-family: sans-serif; text-align: center">
              Explore some creator visits that generated authentic content for our partner destinations!
            </p>
          </div>
        </div>
      </div>

      <ul class="recent-creator-visits__visit-summaries-list">
        <template v-for="(summarySection, index) in summarySections" :key="JSON.stringify(summarySection.itineraries)">
          <CollabSummarySection class="recent-creator-visits__summary-section" :summarySection="summarySection" />

          <div v-if="index !== summarySections.length - 1" style="margin-bottom: 70px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
        </template>
      </ul>
    </div>

    <div class="recent-creator-visits__foot-cta-snippets-list">
      <!-- Shrpa round logo -->
      <div class="shrpa-round-logo recent-creator-visits__srpa-round-logo">
        <img class="shrpa-round-logo__img" src="https://cdn.shrpa.com/images/shrpa_logo_white.png" alt="Shrpa" />
      </div>
      <!-- / Shrpa round logo -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Destinations in need of authentic content?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('ForCommunities')">
          <template #icon><IconEmbedded name="aperture_2" :size="27" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Learn More
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Creators interested in collaborating with destinations?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('BecomingACreator')">
          <template #icon><IconEmbedded name="zap_2" :size="30" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Creator Onboarding
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { getInstance } from "@auth/authWrapper";
import { RouteHelper } from "@helpers/RouteHelper";
import { inject } from "vue";

// Components
import Slider from "@components/Slider.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import CreatorAvatar from "@components/CreatorAvatar.vue";
import Loader from "@components/Loader/Loader.vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";

// Types
import { CollabSummaryModel } from "@contracts/collabCredits";
import CollabSummarySection from "@components/CollabSummarySection.vue";

export default defineComponent({
  name: "HomeV4",

  components: {
    CollabSummarySection,
    IconEmbedded,
    HighlightsSection,
    Slider,
    Loader,
    CreatorAvatar,
    ItineraryTile,
    SrpButton,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalGetLink: inject("globalGetLink") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      isAuthLoading: true,

      summarySections: [] as Array<CollabSummaryModel>,
    };
  },

  async mounted() {
    useHead({ title: "Shrpa - Home" });

    console.info("HomeV4 Mounted");
    // Fire the calls that don't need auth immediately
    await this.reloadUnauthenticatedData();

    // Watch for auth to load before firing the remaining calls
    const self = this;
    const authService = getInstance();
    self.isAuthLoading = authService.loading;
    if (self.isAuthLoading === false) {
      this.reloadAuthenticatedData();
    }
    authService.$watch("loading", loading => {
      if (loading === false) {
        return this.reloadAuthenticatedData();
      }
    });
  },

  methods: {
    async reloadAllData() {
      await this.reloadUnauthenticatedData();
      this.reloadAuthenticatedData();
    },
    // Data that doesn't require any auth and we can fire immediately
    async reloadUnauthenticatedData() {
      this.summarySections = await this.loadPageDetails();
    },
    // Data that should let the auth code run before we send (in case the user is authenticated)
    reloadAuthenticatedData() {
      // None now that we changed how we show themes
    },

    async loadPageDetails(): Promise<Array<CollabSummaryModel>> {
      const uri = `${import.meta.env.VITE_API_URL}/collabsummary/recent`;
      const response = await axios.get(uri);
      this.isLoading = false;

      return response.data;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Pale teal bg ===============================================================
.pale-teal-bg {
  position: relative;
  z-index: 0;
  background: transparent;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 20px);
    position: absolute;
    inset: -20px auto auto 0;
    z-index: -1;
    background: #e6edf0;
    pointer-events: none;
  }
}

.column.promo {
  .promo-wrapper {
    display: flex;
    .icon {
      margin-right: 0.5rem;
    }
    h3.promo-header {
      .content {
        margin-bottom: 1rem;
      }
    }
  }
}

.ui.segment.blank {
  border: none;
  box-shadow: none;
}

.green-section {
  background-color: $brand-color-lighter;
}

.differentiator-text {
  font-size: 0.9em !important;
}

#featured {
  @media screen and (max-width: 420px) {
    margin-top: 2em;
  }
}

#page-title {
  color: rgba(5, 133, 135, 1);
  font-size: 3.2rem;
  margin-top: 2em;
  @media screen and (max-width: 420px) {
    font-size: 2.5em;
    margin-top: 0em;
  }
}

#page-subtitle {
  font-size: 15pt;
  @media screen and (max-width: 420px) {
    font-size: 12pt;
  }
}

#shrpa-cover-photo {
  @media screen and (max-width: 420px) {
    visibility: hidden;
  }
}

//******************** From RecentCreatorVisits.vue (decide if we should unify or kill that page) *******************************/
@import "@/scss/screen-size-ranges.scss";

//Note: Removed the stats styles

// Foot Cta snippet ===========================================================
.foot-cta-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    max-width: 70%;
    margin-bottom: 28px;
    text-align: center;
  }

  &__cta-button {
    outline: none;
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .foot-cta-snippet {
    &__title {
      max-width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .foot-cta-snippet {
    &__title {
      margin-bottom: 15px;
    }
  }
}

// Shrpa round logo ===========================================================
.shrpa-round-logo {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bcc2c5;

  &__img {
    width: 42%;
    position: relative;
    top: -1px;
  }
}

// Recent creator visits ======================================================
.recent-creator-visits {
  &__title-n-stats {
    margin-bottom: 150px;
  }

  &__loader {
    margin-bottom: 150px;
  }

  &__visit-summaries-list {
    width: 1269px;
    padding: 0;
    margin: 0 auto 150px;
    list-style: none;
  }

  &__summary-section {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__foot-cta-snippets-list {
    margin: 0 auto;
    padding: 95px 0 115px;
    display: flex;
    justify-content: center;
    position: relative;
    background: #e7edf0;

    &::before {
      content: "";
      width: 1px;
      height: calc(100% - 67px - 93px);
      position: absolute;
      inset: 67px auto auto 50%;
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &__foot-cta-snippet {
    width: calc(1269px / 2 - 15px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__srpa-round-logo {
    position: absolute;
    inset: auto auto -35px calc(50% - 35px);
  }
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .recent-creator-visits {
    &__visit-summaries-list {
      width: 1104px;
    }

    &__foot-cta-snippet {
      width: calc(1104px / 2 - 12px);
      margin-right: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 100px;
    }

    &__visit-summaries-list {
      width: 762px;
      margin-bottom: 110px;
    }

    &__summary-section {
      margin-bottom: 60px;
    }

    &__foot-cta-snippet {
      width: calc(762px / 2 - 12px) !important;
      margin-right: 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: 502px;
      margin-bottom: 70px;
    }

    &__summary-section {
      margin-bottom: 45px;
    }

    &__foot-cta-snippets-list {
      padding: 60px 0 75px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: 502px;
      margin: 0 0 70px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 502px;
        height: 1px;
        position: absolute;
        inset: -35px auto auto calc(50% - 251px);
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: calc(100% - 36px);
      margin-bottom: 30px;
    }

    &__summary-section {
      margin-bottom: 35px;
    }

    &__foot-cta-snippets-list {
      padding: 55px 0 80px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: calc(100% - 36px);
      margin: 0 0 60px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        inset: -25px auto auto 0;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>
