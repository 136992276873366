<template>
  <div class="select-photos-n-videos">
    <ul class="select-photos-n-videos__items-list">
      <MediaThumbnail
        class="select-photos-n-videos__item"
        v-for="itemId in shareStopSummaryStore.shareableSummaryDetails.assets"
        :key="itemId"
        :mediaUrl="itemId"
        isInputCoversImage
        isOpenFullButtonVisible
      >
        <input type="checkbox" :value="itemId" v-model="shareStopSummaryStore.editedPost.assetIds" />
      </MediaThumbnail>
    </ul>

    <div
      :class="{
        'select-photos-n-videos__bottom-panel': true,
        'select-photos-n-videos__bottom-panel--sticky': shareStopSummaryStore.editedPost.assetIds.length,
      }"
    >
      <!-- Selected files -->
      <div
        :class="{
          'selected-files': true,
          'selected-files--invisible': !shareStopSummaryStore.editedPost.assetIds.length,
          'select-photos-n-videos__selected-files': true,
          'select-photos-n-videos__selected-files--no-margin': !shareStopSummaryStore.editedPost.assetIds.length,
        }"
      >
        <div
          :class="{
            'selected-files__in-wrap-1': true,
            'selected-files__in-wrap-1--invisible': !shareStopSummaryStore.editedPost.assetIds.length,
          }"
        >
          <div class="selected-files__in-wrap-2">
            <div class="selected-files__in-wrap-3">
              <div class="global-h6 selected-files__title">Selected images &amp; videos</div>
              <ul
                :class="{
                  'selected-files__images-list': true,
                  'selected-files__images-list--small': shareStopSummaryStore.editedPost.assetIds.length > 0,
                  'selected-files__images-list--tiny': shareStopSummaryStore.editedPost.assetIds.length > 13,
                }"
              >
                <!-- Image container -->
                <li
                  :class="{
                    'image-container': true,
                    'image-container--small': shareStopSummaryStore.editedPost.assetIds.length > 0,
                    'image-container--tiny': shareStopSummaryStore.editedPost.assetIds.length > 13 || screenSize === 'mobile',
                    'selected-files__image-container': true,
                  }"
                  v-for="itemId in [...shareStopSummaryStore.editedPost.assetIds].reverse()"
                  :key="itemId"
                >
                  <div class="image-container__remove-button" @click="shareStopSummaryStore.removeMediaFromList(itemId)">
                    <IconEmbedded name="remove_2-5" color="rgba(255, 255, 255, 1)" :size="19" />
                  </div>
                  <div class="image-container__image-wrap">
                    <MediaThumbnail class="image-container__image" :mediaUrl="itemId" />
                  </div>
                </li>
                <!-- / Image container -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- / Selected files -->

      <SrpButton class="select-photos-n-videos__next-button" @click="saveAndGoToNextSlide" color="orange" :isDisabled="shareStopSummaryStore.editedPost.assetIds.length === 0">
        Next
        <template #iconRight>
          <IconEmbedded v-if="isSaving" name="loader_3-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
          <IconEmbedded v-else name="arrow-bottom_2-5" color="rgba(255, 255, 255, 0.5)" :size="21" />
        </template>
      </SrpButton>
    </div>
    <div class="select-photos-n-videos__bottom-panel-cover"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, inject, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

// Types
import { SocialPostCreatePayload, SocialMediaPostData } from "@contracts/shareStop";
import { ScreenSize } from "@contracts/screenSize";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import MediaThumbnail from "@components/MediaThumbnail.vue";

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
const shareStopSummaryStore = useShareStopSummaryStore();

const screenSize = inject("screenSize") as Ref<ScreenSize>;

// ============================================================================
const isSaving = ref(false);
async function saveAndGoToNextSlide(): Promise<void> {
  const pageId = String(route.params.pageId);

  const payload: SocialPostCreatePayload = {
    contextTitle: shareStopSummaryStore.editedPost.contextTitle,
    contextType: shareStopSummaryStore.editedPost.contextType,
    contextId: shareStopSummaryStore.editedPost.contextId,
    assetIdsToInclude: [...shareStopSummaryStore.editedPost.assetIds],
  };

  isSaving.value = true;

  if (shareStopSummaryStore.editedPost.id) await shareStopSummaryStore.updatePost(shareStopSummaryStore.editedPost);
  else await shareStopSummaryStore.savePost(pageId, payload);

  await router.push({
    name: "ShareStopSummaryV2",
    params: { pageId: pageId },
    query: { postId: shareStopSummaryStore.editedPost.id },
  });

  isSaving.value = false;

  emit("goToNextSlide");
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Image container ============================================================
.image-container {
  width: 85px;
  min-width: 85px;
  height: 85px;
  min-height: 85px;
  position: relative;
  border-radius: 4px;

  &--small {
    width: 56px;
    min-width: 56px;
    height: 56px;
    min-height: 56px;
  }

  &--tiny {
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
  }

  &__remove-button {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: -4px -4px auto auto;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: black;
      transition: transform 0.08s ease-in-out;
      cursor: pointer;
    }

    &:hover::after {
      transform: scale(1.2);
    }
  }

  &__image-wrap {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(.media-thumbnail-checkbox__video-wrap) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(.media-thumbnail-checkbox__video) {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    :deep(.icon.play.circle.outline) {
      font-size: 29px;
      line-height: 29px;
    }
  }
}

// Selected files =============================================================
.selected-files {
  display: flex;
  align-items: flex-end;
  opacity: 1;
  transition:
    margin-bottom 0.07s ease-in-out,
    opacity 0.07s ease-in-out;

  &--invisible {
    opacity: 0;
  }

  &__in-wrap-1 {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    transition:
      grid-template-rows 0.07s ease-in-out,
      opacity 0.07s ease-in-out;

    &--invisible {
      grid-template-rows: 0fr;
    }
  }

  &__in-wrap-2 {
    border: 1px #b4b4b4 solid;
    border-radius: 6px;
    background: #f3f3f3;
    position: relative;
    overflow: hidden;
  }

  &__in-wrap-3 {
    padding: 13px 21px 10px;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__images-list {
    width: calc(100% + 10px);
    min-height: 80px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &--small {
      min-height: 56px;
    }

    &--tiny {
      min-height: 35px;
    }
  }

  &__image-container {
    margin: 0 10px 10px 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .selected-files {
    &__images-list {
      padding-top: 5px;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
}

// Select photos & videos =====================================================
.select-photos-n-videos {
  position: relative;

  &__items-list {
    padding: 0;
    margin: 0 0 32px 0;
    column-count: 5;
    column-gap: 10px;
    list-style: none;
  }

  &__item {
    margin: 0 0 10px 0 !important;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    break-inside: avoid-column;
    page-break-inside: avoid;
    background: rgba(0, 0, 0, 0.1);
  }

  &__bottom-panel {
    padding-bottom: 12px;
    inset: auto auto 0 0;

    &--sticky {
      position: sticky;
      z-index: 3;

      &::before {
        content: "";
        width: 100%;
        height: calc(100% + 40px);
        position: absolute;
        inset: auto auto 0 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
        pointer-events: none;
      }
    }
  }

  &__bottom-panel-cover {
    width: 100%;
    height: 1px;
    position: sticky;
    inset: auto auto 0 0;
    z-index: 2;
    background: red;
    pointer-events: none;
    display: none;

    &::before {
      content: "";
      width: 100%;
      height: 120px;
      position: absolute;
      inset: auto auto 0 0;
      background: white;
    }
  }

  &__selected-files {
    margin-bottom: 12px;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__next-button {
    outline: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .select-photos-n-videos {
    &__items-list {
      column-count: 3;
    }

    &__bottom-panel {
      padding-bottom: 12px;
      inset: auto auto 120px 0;
    }

    &__bottom-panel-cover {
      display: block;
    }
  }
}
</style>
