<template>
  <div class="planned-visit">
    <!-- Title & text -->
    <div class="title-n-text planned-visit__title-n-text">
      <IconEmbedded class="planned-visit__icon" name="bed_1-5" :size="26" />
      <div>
        <div class="global-h6 title-n-text__title">Lodging Details</div>

        <div class="title-n-text__description">
          <!-- Community set -->
          <template v-if="communityCoveringHotel">
            <template v-if="collabInput.hotelName && collabInput.hotelName.length > 0">
              <template v-if="collabInput.hotelName">{{ collabInput.hotelName }}<br /></template>
              <template v-if="collabInput.hotelDateBooked">{{ collabInput.hotelDateBooked }}<br /></template>
              <div v-if="collabInput.hotelIsBooked"><i class="check circle icon" style="color: #058587"></i>Lodging is coordinated.</div>
              <div v-else><i class="hourglass half orange circle icon"></i>Not yet marked coordinated.</div>
            </template>
            <template v-else>The destination hasn't filled this out yet.</template>
          </template>

          <!-- Creator set -->
          <template v-else-if="creatorCoveringHotel">
            <div class="column">
              <div class="field">
                <p>Update the lodging details here.</p>
                <div class="ui input" style="width: 100%">
                  <input type="text" v-model="props.collab.creatorInput.hotelName" placeholder="Lodging name" />
                </div>
              </div>
              <div class="field">
                <div class="ui input" style="width: 100%; margin: 10px 0">
                  <input type="text" v-model="props.collab.creatorInput.hotelDateBooked" placeholder="Lodging Dates" />
                </div>
              </div>
              <div class="field">
                <div class="ui checkbox small">
                  <input type="checkbox" class="hidden" id="hotelIsBooked" v-model="props.collab.creatorInput.hotelIsBooked" />
                  <label class="small" for="hotelIsBooked">Lodging is booked</label>
                </div>
              </div>
            </div>

            <!-- Post-Accept Save -->
            <div class="column" v-if="collabInput.collaboration.completedDate == null">
              <button v-if="creatorCoveringHotel" class="ui small primary right floated button" @click="emit('save')">Save</button>
              <br />
              <h4 v-if="props.saveStatus" style="text-align: right">{{ props.saveStatus }}</h4>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- / Title & text -->

    <!-- Title & text -->
    <div v-if="hasBigTicketItemsComped" class="title-n-text planned-visit__title-n-text">
      <IconEmbedded class="planned-visit__icon" name="activity_1-5" :size="26" />
      <div>
        <div class="global-h6 title-n-text__title">Comped Activities <span class="title-n-text__description">(coordinated by destination)</span></div>
        <template v-for="tag in collabCompTags">
          <button
            v-if="collabInput.compedActivitiesTags.includes(tag.key)"
            :key="tag.key"
            class="ui disabled mini basic primary button comp-tag"
            style="height: 23px; display: inline-flex; align-items: center; opacity: 1 !important"
          >
            <IconEmbedded :name="tag.icon" :size="17" color="rgba(5, 133, 135, 1)" />
            {{ tag.text }}
          </button>
        </template>
        <div class="title-n-text__description">
          <div v-if="collabInput.bigTicketItemBookinNotes?.length > 0" style="white-space: pre-wrap">
            {{ collabInput.bigTicketItemBookinNotes }}<br />
            <div v-if="collabInput.bigTicketItemsAllBooked"><i class="check circle icon" style="color: #058587"></i>Activities marked coordinated.</div>
            <div v-else><i class="hourglass half orange circle icon"></i>Not yet marked coordinated.</div>
          </div>
          <div v-else>The destination hasn't given specific details yet.</div>
        </div>
      </div>
    </div>
    <!-- / Title & text -->

    <!-- Title & text -->
    <div v-if="collabInput.destinationContactName || collabInput.destinationPhoneNumber" class="title-n-text planned-visit__title-n-text">
      <IconEmbedded class="planned-visit__icon" name="phone_1-5" :size="25" />
      <div>
        <div class="global-h6 title-n-text__title">Destination Contact</div>

        <div class="title-n-text__description">
          <template v-if="collabInput.destinationContactName">
            <b>{{ collabInput.destinationContactName }}</b
            >&nbsp;
          </template>
          {{ collabInput.destinationPhoneNumber }}
        </div>
      </div>
    </div>
    <!-- / Title & text -->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

// Types
import { CreatorCollab, CollabInput, CollabCreatorInput } from "@contracts/collab";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CollabConsts from "@contracts/collab";

export interface Props {
  collab: CreatorCollab | null;
  saveStatus: string | null;
}
const props = withDefaults(defineProps<Props>(), {
  collab: null,
  saveStatus: "",
});

const emit = defineEmits<{
  (e: "save"): void;
}>();

// ============================================================================
const collabCompTags = CollabConsts.CollabCompTags;

const collabInput = computed<CollabInput | null>(() => props.collab?.collabInput);
const creatorInput = computed<CollabCreatorInput | null>(() => props.collab?.creatorInput);

const communityCoveringHotel = computed<boolean>(() => {
  return collabInput.value.hotelNightsCovered > 0 && creatorInput.value.nightsRequired > 0;
});

const creatorCoveringHotel = computed<boolean>(() => {
  return collabInput.value.hotelNightsCovered === 0 && creatorInput.value?.nightsRequired > 0;
});

const hasBigTicketItemsComped = computed<boolean>(() => {
  return collabInput.value.compedActivitiesTags?.length > 0 || collabInput.value.bigTicketItemBookinNotes?.length > 0;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Title & text ===============================================================
.title-n-text {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
  &__title {
    margin-bottom: 3px;
  }

  &__description {
    color: #5b5b5b;
    font-family: sans-serif;
  }
}

// Planned visit ==============================================================
.planned-visit {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__title-n-text {
    width: calc(100% - 14px);
    margin-right: 27px;
    padding-top: 1rem;

    border-top: 1px solid rgba(0, 0, 0, 0.2);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .planned-visit {
    &__title-n-text {
      width: calc(100% - 9px);
      margin-right: 18px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .planned-visit {
    &__title-n-text {
      width: calc(100% - 9px);
      margin-right: 17px;
    }
  }
}
</style>
