<template>
  <SrpDetailsSummary class="creator-visits-page__planned-visits-spoiler" :isOpened="true" :transitionDuration="120">
    <template #heading="{ isOpened }">
      <h3 class="global-h3">
        <span><span>Planned Visits</span></span>
      </h3>
    </template>
    <template #details="{ isOpened }">
      <div v-if="plannedCollabs.length" class="planned-visits-list" :style="{ marginTop: isOpened ? '5px' : 0 }">
        <PlannedCollabSnippet
          v-for="(plannedCollab, idx) in plannedCollabs"
          :key="`${plannedCollab.id}-${idx}`"
          :planned-collab="plannedCollab"
          class="planned-visits-list__snippet"
          @click:edit="showModalAtIndex(idx)"
          @click:get-started="emit('click:getStarted', plannedCollab)"
        />
      </div>
      <div v-if="plannedCollabs.length === 0">
        <div style="margin-bottom: 10px">A quick way to plan your visit calendar.</div>
        <SrpButton size="small" @click="showModalAtIndex(0)">
          <template #icon> <IconEmbedded name="calendar_2" :size="18" /> </template>Start Planning
        </SrpButton>
      </div>
      <p v-else-if="props.expectedPlannedCollabsCount - plannedCollabs.length > 0">
        <SrpButton color="orange" @click="showModalAtIndex(props.expectedPlannedCollabsCount - plannedCollabs.length - 1)" style="margin-top: 10px">Finish Planning</SrpButton>
      </p>
    </template>
  </SrpDetailsSummary>

  <PlannedCollabsModal v-model:is-visible="isPlannedCollabsFormVisible" :active-plan-index="activePlanIndex" :planned-collabs="plannedCollabsWithAddedStubs" @save="savePlannedCollabs" />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick } from "vue";
import PlannedCollabsRepo, { type PlannedCollab } from "@repos/PlannedCollabsRepo";
import PlannedCollabSnippet from "./PlannedCollabSnippet.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import PlannedCollabsModal from "./PlannedCollabsModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { RouteHelper } from "@helpers/RouteHelper";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { TransitionScale } from "@morev/vue-transitions";

// Stores
import { useCollabSuggestionsStore } from "@stores/collabSuggestionsStore";
const collabSuggestionsStore = useCollabSuggestionsStore();

const props = defineProps<{
  customerId: string;
  plannedCollabs: PlannedCollab[];
  expectedPlannedCollabsCount: number;
  createdCollabCount: number;
}>();

const emit = defineEmits<{
  (e: "savePlannedCollabs", plannedCollabs: PlannedCollab[]): void;
  (e: "click:getStarted", plannedCollab: PlannedCollab): void;
}>();

const activePlanIndex = ref(-1);
const isPlannedCollabsFormVisible = ref(false);
function showModalAtIndex(index: number) {
  activePlanIndex.value = index;
  isPlannedCollabsFormVisible.value = true;
}

const plannedCollabsWithAddedStubs = computed(() => ensurePlannedCollabCount(props.plannedCollabs));

/**
 * Create in memory planned collabs if the number of planned collabs is less than the
 * expected number of planned collabs. These collabs won't be saved unless they are completed
 * by adding a name and a start date.
 *
 * @returns a new array with the correct number of planned clollabs
 */
function ensurePlannedCollabCount(data: PlannedCollab[]): PlannedCollab[] {
  const plannedCollabsInMemory = [...data];
  const numberOfCollabsToCreate = props.expectedPlannedCollabsCount - plannedCollabsInMemory.length;
  if (numberOfCollabsToCreate > 0) {
    for (let i = 0; i < numberOfCollabsToCreate; i++) {
      // Count the previous collabs created/completed when we're naming the planned ones
      const indexToUseInName = props.createdCollabCount + plannedCollabsInMemory.length + 1;
      plannedCollabsInMemory.push(createStubbedPlannedCollab(`Creator Visit ${indexToUseInName}`));
    }
  }
  return plannedCollabsInMemory;
}

function createStubbedPlannedCollab(name: string): PlannedCollab {
  return {
    id: null,
    name,
    notes: "",
    preferredDateRangeStart: null,
    preferredDateRangeEnd: null,
    createdCollaborationId: null,
    customerId: props.customerId,
    themeType: null,
    requiredLocations: null,
    suggestedLocations: null,
  };
}

// Check for a showPlannedVisits query param and show the modal if it's present
onMounted(() => {
  const showPlannedVisits = RouteHelper.getQueryStringOrHashParam("showPlannedVisits");
  if (showPlannedVisits === "true") {
    showModalAtIndex(0);
    // clear the hash so we don't show the modal again on refresh
    window.location.hash = "";
  }
});

async function savePlannedCollabs(updatedPlannedCollabs: PlannedCollab[]) {
  // only send planned collabs that have a name and a preferred date range
  const collabsToSend = updatedPlannedCollabs.filter(collab => collab.name && collab.preferredDateRangeStart);
  emit("savePlannedCollabs", collabsToSend);
}

// Check is there any collab suggestions to save ==============================
onMounted(async () => {
  const collabSuggestionId = RouteHelper.getHashParam("collabSuggestionIdToSaveAsPlanned");
  const startDate = RouteHelper.getHashParam("startDate");
  const endDate = RouteHelper.getHashParam("endDate");

  if (collabSuggestionId) {
    const collabSuggestionToSave = await collabSuggestionsStore.getCollabSuggestion(props.customerId, collabSuggestionId);

    const newPlannedCollab: PlannedCollab = {
      id: null,
      customerId: props.customerId,
      createdCollaborationId: null,
      name: collabSuggestionToSave.title,
      preferredDateRangeStart: startDate,
      preferredDateRangeEnd: endDate === "null" ? null : endDate,
      themeType: collabSuggestionToSave.theme,
      notes: collabSuggestionToSave.visitFocus,

      createdFromCollabSuggestionId: collabSuggestionToSave.id,

      requiredLocations: collabSuggestionToSave.requiredLocations,
      suggestedLocations: collabSuggestionToSave.suggestionLocations,
    };

    await nextTick();
    await savePlannedCollabs([...props.plannedCollabs, newPlannedCollab]);
    await nextTick();

    // removes all hash values to prevent the creation another identical PlannedCollab on reload
    await router.replace({ ...route, hash: "" });
  }
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.creator-visits-page {
  &__planned-visits-spoiler {
    width: calc(100% + 50px);
    padding: 20px 25px 25px;
    margin: 0 0 35px -25px;
    border-radius: 6px;
    background: #e7edf0;
  }
}
.planned-visits-list {
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 5px;

  &__snippet {
    width: calc(50% - 13px);
    margin: 26px 26px 0 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .planned-visits-list {
    &__snippet {
      width: 100%;
      margin-right: 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .planned-visits-list {
    &__snippet {
      width: 100%;
      margin-top: 17px;
      margin-right: 0;
    }
  }
}
</style>
