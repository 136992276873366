<template>
  <div class="create-tab">
    <!-- Header -->
    <div class="header create-tab__header">
      <h2 class="global-h2 header__title">Upload Photos & Videos from your Visit!</h2>
      <div class="header__subtitle-n-remove-mode-btn">
        <div class="header__subtitle">
          <template v-if="collabInput.useLocationsInAdventures">
            <NoteWithIcon class="collab-opportunity__warning" color="blue" size="tiny" icon="info-simple_4">
              <template #text>
                <!--<h3 class="global-h3">Collaboration Complete!</h3>-->
                <span> All photos and videos will be delivered to the destination, even those not included in adventures. </span>
              </template>
            </NoteWithIcon>
          </template>
          <NoteWithIcon v-else color="yellow" icon="info-simple_4">
            <template #text>
              This collab was completed before the new collaboration flow was added.
              <br />Locations and photos can be viewed in the adventures for this collaboration.
            </template>
          </NoteWithIcon>
        </div>

        <LinkWithIcon v-if="collabLocationsStore.locations?.length > 0 && !props.collabInput?.collaboration?.completedDate" isDottedUnderline @click="isDeleteThumbnailsMode = !isDeleteThumbnailsMode">
          <template #icon><IconEmbedded name="trashcan-alt_2" :size="20" /></template>
          <span>{{ isDeleteThumbnailsMode ? "Cancel remove mode" : "Select files to remove" }}</span>
        </LinkWithIcon>
      </div>
    </div>
    <!-- / Header -->
    <template v-if="collabInput.useLocationsInAdventures">
      <div class="create-tab__location-sections-list" ref="domRefLocationSectionsList">
        <SrpDetailsSummary class="create-tab__location-section" v-for="(location, locationIndex) in collabLocationsStore.locations" :key="location?.id" :transitionDuration="100" isKeepAlive isOpened>
          <template #heading>
            <div class="global-h3">
              <span
                ><span>
                  <span style="width: 10px; height: 10px; margin: 0 10px 0 5px; display: inline-flex; justify-content: center; align-items: center; position: relative; top: -2px">
                    <IconEmbedded name="map-pin_2" color="rgba(0, 0, 0, 0.3)" :size="26" style="position: absolute; inset: calc(50% - 13px) auto auto calc(50% - 13px)" /> </span
                  >{{ location?.title || "Location" }}
                </span></span
              >&nbsp;
              <span v-if="location?.mediaIds?.length" style="color: rgba(0, 0, 0, 0.3); font-weight: 600; white-space: nowrap">
                {{ location?.mediaIds?.length }}
              </span>
            </div>
          </template>
          <template #details>
            <div style="padding: 0 6px">
              <!-- Thumbnails list -->
              <div class="thumbnails-list" style="padding-top: 6px">
                <UploadPhotoForm
                  v-if="!isDragInProgress && !isTouchDragInProgress"
                  class="thumbnails-list__upload-photo-form"
                  :img="[]"
                  :singlePickMode="false"
                  :autoSelect="false"
                  :isCollab="true"
                  @imageUploadedToServer="mediaFile => addMediaFileToLocation(locationIndex, mediaFile)"
                  ref="domRefUploadPhotoForms"
                  :data-index="locationIndex"
                  @uploadProgressChange="value => (uploadProgress[locationIndex] = value)"
                  @uploadingStarted="clearErrors"
                  @fileTooSmallError="fileName => tooSmallImagesArray.push(fileName)"
                  @invalidFileType="fileName => addInvalidFileTypeError(fileName)"
                />

                <!-- Upload progress bar -->
                <div class="upload-progress-bar thumbnails-list__upload-progress-bar" v-if="uploadProgress[locationIndex]?.isUploadInProgress">
                  <div class="upload-progress-bar__text">Uploading</div>
                  <div class="upload-progress-bar__scale-wrap">
                    <div class="upload-progress-bar__scale" :style="{ width: `${uploadProgress[locationIndex]?.uploadProgress}%` }"></div>
                  </div>
                </div>
                <!-- / Upload progress bar -->

                <div class="thumbnails-list__list-itself">
                  <!-- Thumbnail wrap -->
                  <div
                    :class="{
                      'thumbnail-wrap': true,
                      'thumbnail-wrap--with-left-highlighting-line': false,
                      'thumbnail-wrap--with-right-highlighting-line': false,
                      'thumbnail-wrap--with-gray-overlay': false,
                      'thumbnails-list__thumbnail-wrap': true,
                    }"
                    v-for="(fileName, fileIndex) in location?.mediaIds"
                    :key="fileName"
                    :data-locationIndex="locationIndex"
                    :data-index="fileIndex"
                    ref="domRefThumbnailWrap"
                  >
                    <IconEmbedded
                      :class="{
                        'thumbnail-wrap__drag-n-drop-handle': true,
                        'thumbnail-wrap__drag-n-drop-handle--disabled': collabLocationsStore.isLoading,
                      }"
                      name="drag-n-drop-handle-3-lines"
                      color="rgba(0, 0, 0, 0.3)"
                      :size="24"
                      :data-locationIndex="locationIndex"
                      :data-index="fileIndex"
                    />
                    <SrpFileThumbnail
                      class="thumbnail-wrap__thumbnail"
                      :fileName="fileName"
                      :isFixedAspectRatio="true"
                      :isEntireAreaClickable="false"
                      :galleryPostfix="String(locationIndex)"
                      :isDeleteButton="isDeleteThumbnailsMode"
                      @delete="removeMediaFromLocation(location, fileIndex)"
                    />
                  </div>
                  <!-- / Thumbnail wrap -->

                  <!-- Placeholder images -->
                  <template v-if="location?.mediaIds?.length < 5">
                    <!-- Thumbnail wrap -->
                    <div
                      :class="{
                        'thumbnail-wrap': true,
                        'thumbnail-wrap--with-left-highlighting-line': false,
                        'thumbnail-wrap--with-right-highlighting-line': false,
                        'thumbnails-list__thumbnail-wrap': true,
                        'thumbnails-list__thumbnail-wrap--placeholder': true,
                        'thumbnails-list__thumbnail-wrap--invisible-for-clicks': !isDragInProgress && !isTouchDragInProgress,
                      }"
                      v-for="(fileName, filePlaceholderIndex) in (['image', 'video', 'image', 'video', 'image'] as const).slice(location?.mediaIds?.length)"
                      :key="fileName"
                      :data-locationIndex="locationIndex"
                      :data-index="filePlaceholderIndex + location?.mediaIds?.length"
                      ref="domRefThumbnailWrapPlaceholder"
                    >
                      <SrpFileThumbnail class="thumbnail-wrap__thumbnail thumbnail-wrap__thumbnail--pointer-events-none" :placeholderType="fileName" :isFixedAspectRatio="true" />
                    </div>
                    <!-- / Thumbnail wrap -->
                  </template>
                  <!-- / Placeholder images -->

                  <!-- Thumbnail wrap -->
                  <div
                    :class="{
                      'thumbnail-wrap': true,
                      'thumbnails-list__thumbnail-wrap': true,
                      'thumbnails-list__thumbnail-wrap--invisible-for-clicks': true,
                    }"
                  >
                    <!-- Upload button -->
                    <div class="upload-btn thumbnail-wrap__thumbnail" @click="clickUploadButton(locationIndex)">
                      <IconEmbedded class="upload-btn__icon" name="upload_2" :size="26" color="rgba(0, 0, 0, 0.5)" />
                      <div class="upload-btn__text">Upload</div>
                    </div>
                    <!-- / Upload button -->
                  </div>
                  <!-- / Thumbnail wrap -->
                </div>
              </div>
              <!-- / Thumbnails list -->
            </div>
          </template>
        </SrpDetailsSummary>
      </div>
      <PlanEditModal
        :showVisitPlanFields="false"
        :isVisible="isAddLocationModalVisible"
        :communityId="props.customerId"
        :collabInput="props.collabInput"
        :creatorInput="null"
        :creatorId="props.creatorId"
        @update:isVisible="v => (isAddLocationModalVisible = v)"
      />

      <div class="create-tab__add-location-btn-n-stats">
        <SrpButton class="create-tab__add-location-btn" fill="outlined" @click="isAddLocationModalVisible = true">
          <template #icon><IconEmbedded name="plus_3-5" :size="22" /></template>
          Add another location
        </SrpButton>

        <div class="global-h3 create-tab__stats">{{ imagesCount }} photos, {{ videosCount }} videos</div>
      </div>

      <div class="create-tab__divider"></div>

      <NoteWithIcon v-if="totalMediaFilesAmount > MaxMediaCountWarning" style="margin-bottom: 25px" ref="domRefTooManyPhotosWarningNote">
        <template #text>
          <div>
            <h5 class="global-h5" style="margin-bottom: 4px">Too Many Photos?</h5>
            <span style="margin-bottom: 10px; display: flex">
              Typical collabs have between 125 and 200 photos. More than this usually suggests duplication or reduced quality.<br />
              We recommend removing some photos or videos to highlight the best ~150 for the destination.
            </span>

            <LinkWithIcon
              v-if="collabLocationsStore.locations?.length > 0 && !props.collabInput?.collaboration?.completedDate"
              isDottedUnderline
              @click="isDeleteThumbnailsMode = !isDeleteThumbnailsMode"
              style="margin-left: -3px"
            >
              <template #icon><IconEmbedded name="trashcan-alt_2" :size="20" /></template>
              <span>{{ isDeleteThumbnailsMode ? "Cancel remove mode" : "Select files to remove" }}</span>
            </LinkWithIcon>
          </div>
        </template>
      </NoteWithIcon>

      <div class="create-tab__validation-n-next-btn-section">
        <div class="create-tab__validation">
          <div v-if="invalidFileTypesArray.length">
            <span v-for="(fileName, index) in invalidFileTypesArray" :key="fileName">
              {{ (index === 0 ? "" : ", ") + fileName }}
            </span>
            files are not supported.
            <span v-if="invalidFileTypesArray.some(i => i.toLowerCase().endsWith('heic'))" style="font-weight: normal">
              See <a :href="getGlobalLink('ConvertingHeicToJpeg')" target="_blank">{{ getGlobalLink("ConvertingHeicToJpeg") }}</a>
            </span>
          </div>
          <div v-if="tooSmallImagesArray.length">
            Uploads must be {{ MinCollabFileSizeErrorInKB }}KB:
            <span v-for="(fileName, index) in tooSmallImagesArray" :key="fileName" style="font-weight: normal">
              {{ (index === 0 ? "" : ", ") + fileName }}
            </span>
          </div>
        </div>

        <div style="display: flex; align-items: center">
          <div class="global-h5" style="padding-bottom: 4px; margin-right: 12px; display: flex; align-items: center">
            {{ saveStatusString }}
            <EllipsisAnimated v-if="[SaveStatus.Saving, SaveStatus.PreparingToSave].includes(saveStatus)" />
          </div>

          <SrpButton class="create-tab__next-btn" size="big" @click="saveAndGoToNextTab" :isDisabled="[SaveStatus.Saving, SaveStatus.PreparingToSave].includes(saveStatus)">
            Next
            <template #iconRight><IconEmbedded name="arrow-right_3" :size="26" /></template>
          </SrpButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject, Ref, toRef, onUnmounted, onMounted, onBeforeUnmount, nextTick } from "vue";
import FileUtils from "@logic/FileUtils";
import { getGlobalLink } from "@logic/ExternalLinks";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { UploadedImage } from "@contracts/uploadedImage";

// Stores
import { useCollabLocationsStore } from "@stores/collabLocations";
const collabLocationsStore = useCollabLocationsStore();

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import UploadPhotoForm, { MinCollabFileSizeErrorInKB } from "@views/CMS/UploadPhotoForm.vue";
import PlanEditModal from "../../PlanEditModal/index.vue";

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Composables
import { AreaQuarter, useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";
import throttle from "lodash-es/throttle";
import { CollabInput } from "@contracts/collab";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import { CollabLocation } from "@contracts/collabLocations";
import axios from "axios";
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";
import { useFloatingProblemBadges } from "@composables/useFloatingProblemBadges";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
const { upsertBadge, checkIfBadgeExists, removeBadge } = useFloatingProblemBadges();

const props = defineProps<{
  collabInput: CollabInput;
  creatorId: string;
  customerId: string;
}>();

const emit = defineEmits<{
  (e: "goToNextTab"): void;
  (e: "goToCreateTab"): void;
}>();

// Stats =====================================================
const allMediaFiles = computed<Array<string>>(() => collabLocationsStore.locations.map(i => i?.mediaIds).flat());
const videosCount = computed<number>(() => allMediaFiles.value.filter(i => FileUtils.isVideoFileType(i)).length);
const imagesCount = computed<number>(() => allMediaFiles.value.length - videosCount.value);

// Add media file to the location =============================================
function addMediaFileToLocation(locationIndex: number, uploadedImage: UploadedImage) {
  collabLocationsStore.locations[locationIndex].mediaIds.push(uploadedImage.serverId);
  requestSavingData();
}

// Remove media from the location ============================================
// Keep a list of files to be removed
let mediaToRemoveFromAdventures: Array<string> = [];
function removeMediaFromLocation(location: CollabLocation, indexOfMediaToRemove: number) {
  // Keep track of media to remove from adventures (this gets persisted on the save call)
  mediaToRemoveFromAdventures.push(location.mediaIds[indexOfMediaToRemove]);
  // Remove it from the location data
  location.mediaIds = location.mediaIds.filter((_, index) => index !== indexOfMediaToRemove);
  // Save
  requestSavingData();
}

// Dom ref for UploadPhotoForms ===============================================
const domRefUploadPhotoForms = ref<InstanceType<typeof UploadPhotoForm>[]>([]);

function clickUploadButton(locationIndex: number) {
  const targetUploadPhotoForm = domRefUploadPhotoForms.value.find(i => i.$attrs["data-index"] === locationIndex);
  targetUploadPhotoForm.clickUploadImage();
}

// Set upload progress ========================================================
const uploadProgress = ref<Array<{ isUploadInProgress: boolean; uploadProgress: number }>>([]);

// Save and go to next tab ====================================================
async function saveAndGoToNextTab() {
  await collabLocationsStore.saveCollabLocationsData(props.customerId, props.collabInput.id, props.creatorId);
  emit("goToNextTab");
}

// Save logic ========================================================
enum SaveStatus {
  None, // Nothing to save
  PreparingToSave, // Since we throttle saves, this is while we're waiting for the throttle to fire
  Saving, // Making the api call
  Saved, // Api call complete
}
const saveStatus = ref<SaveStatus>(SaveStatus.None);
const saveStatusString = computed(() => {
  switch (saveStatus.value) {
    case SaveStatus.Saving:
      return "Saving";
    case SaveStatus.PreparingToSave:
      return "Saving";
    // return "Saving soon...";
    // return "Preparing to save";
    case SaveStatus.Saved:
      return "Saved";
    default:
      return "";
  }
});
async function saveData() {
  saveStatus.value = SaveStatus.Saving;
  await saveLocationsList();
  // Check if we have any media to remove from the collab
  if (mediaToRemoveFromAdventures.length) {
    // Make a local copy in case more rows get added while this runs (shallow copy is fine since it's just an array of strings)
    const mediaToRemoveFromAdventuresCopy = mediaToRemoveFromAdventures.slice();
    mediaToRemoveFromAdventures = [];
    await saveRemovedMedia(mediaToRemoveFromAdventuresCopy);
  }
  saveStatus.value = SaveStatus.Saved;
  // Only show that for a few seconds
  setTimeout(() => {
    if (saveStatus.value === SaveStatus.Saved) saveStatus.value = SaveStatus.None;
  }, 3000);
}

async function saveRemovedMedia(mediaIdsToRemove: Array<string>) {
  // Build the uri and call the endpoint to remove the images from the collab adventures
  const querystring = mediaIdsToRemove.map(i => `imageIdsToDelete=${i}`).join("&");
  let uri = `${import.meta.env.VITE_API_URL}/cms-images/collab/${props.collabInput.id}/customer/${props.customerId}?${querystring}`;
  await axios.delete(uri);
}

async function saveLocationsList() {
  await collabLocationsStore.saveCollabLocationsData(props.customerId, props.collabInput.id, props.creatorId);
  isAddLocationModalVisible.value = false;
}
/** Throttled call to save locations. The save function itself will be called *at most* one time every 5000 ms on the trailing edge of the time window */
function requestSavingData() {
  if (saveStatus.value === SaveStatus.None || saveStatus.value === SaveStatus.Saved) saveStatus.value = SaveStatus.PreparingToSave;
  // Let the throttle function decide when to save
  throttledSaveData();
}
const throttledSaveData = throttle(saveData, 3000, { leading: false });
// when the component is unmounted, flush any remaining calls
onUnmounted(() => throttledSaveData.flush());

// Is "Add Location" modal visible ============================================
const isAddLocationModalVisible = ref<boolean>(false);

// Set up drag&drop ===========================================================
const domRefLocationSectionsList = ref<HTMLElement>(null);

function moveThumbnailCallback(elemIdxFrom: number, elemIdxTo: number, { locationindex: locationIdxFrom }: DOMStringMap, { locationindex: locationIdxTo }: DOMStringMap, areaQuarter: AreaQuarter) {
  if (locationIdxFrom === locationIdxTo && elemIdxFrom === elemIdxTo) return; // when image is dropped on its own area

  const locationFrom = collabLocationsStore.locations[locationIdxFrom],
    locationTo = collabLocationsStore.locations[locationIdxTo];

  const draggedElem = locationFrom.mediaIds[elemIdxFrom];

  locationFrom.mediaIds[elemIdxFrom] = null; // we shouldn't remove the dragged element just yet because it can shift all the indexes in the array

  locationTo.mediaIds.splice([1, 4].includes(areaQuarter) ? elemIdxTo : elemIdxTo + 1, 0, draggedElem);

  locationFrom.mediaIds = locationFrom.mediaIds.filter(i => i !== null); // now we should filter instead of direct access by index because the index can change after the previous command

  requestSavingData();
}

const { isDragInProgress, elementDatasetDragged, elementDatasetDraggedOver, elementAreaQuarterDraggedOver } = useMouseDragNDrop(
  toRef(true),
  allMediaFiles,
  domRefLocationSectionsList,
  ".thumbnail-wrap.thumbnails-list__thumbnail-wrap",
  ".thumbnail-wrap__drag-n-drop-handle",
  moveThumbnailCallback
);

const { isTouchDragInProgress, elementDatasetTouchDragged, elementDatasetTouchDraggedOver, elementAreaQuarterTouchDraggedOver } = useTouchDragNDrop(
  toRef(true),
  allMediaFiles,
  domRefLocationSectionsList,
  ".thumbnail-wrap.thumbnails-list__thumbnail-wrap",
  ".thumbnail-wrap__drag-n-drop-handle",
  moveThumbnailCallback,
  20
);

// Toggle thumbnail left line and gray overlay ================================
const domRefThumbnailWrap = ref<Array<HTMLElement>>(null);
const domRefThumbnailWrapPlaceholder = ref<Array<HTMLElement>>(null);

function toggleGrayOverlayClass(newValue: Array<DOMStringMap>, oldValue: Array<DOMStringMap>, className: string): void {
  const _newValue = newValue[0] || newValue[1];
  const _oldValue = oldValue[0] || oldValue[1];

  const thumbnails: Array<HTMLElement> = domRefThumbnailWrap.value;
  const placeholders: Array<HTMLElement> = domRefThumbnailWrapPlaceholder.value;

  function findElem(list: Array<HTMLElement>, dataset: DOMStringMap): HTMLElement {
    return list.find(i => i.dataset.index === dataset?.index && i.dataset.locationindex === dataset?.locationindex);
  }

  if (_oldValue) {
    const targetElem: HTMLElement = findElem(thumbnails, _oldValue) || findElem(placeholders, _oldValue);
    targetElem?.classList.remove(className);
  }

  if (_newValue) {
    const targetElem: HTMLElement = findElem(thumbnails, _newValue) || findElem(placeholders, _newValue);
    targetElem?.classList.add(className);
  }
}

watch(
  () => [elementDatasetDragged.value, elementDatasetTouchDragged.value],
  (newValue, oldValue) => toggleGrayOverlayClass(newValue, oldValue, "thumbnail-wrap--with-gray-overlay")
);

function toggleHighlightingLineClass(newDOMStringMap: DOMStringMap, oldDOMStringMap: DOMStringMap): void {
  // This is a perf optimization so the vue reactivity system doesn't have to recalc this style every time the drag state changes
  const thumbnails: Array<HTMLElement> = domRefThumbnailWrap.value;
  const placeholders: Array<HTMLElement> = domRefThumbnailWrapPlaceholder.value;

  function findElem(list: Array<HTMLElement>, dataset: DOMStringMap): HTMLElement {
    return list.find(i => i.dataset.index === dataset?.index && i.dataset.locationindex === dataset?.locationindex);
  }

  if (oldDOMStringMap) {
    const targetElem: HTMLElement = findElem(thumbnails, oldDOMStringMap) || findElem(placeholders, oldDOMStringMap);
    targetElem?.classList.remove("thumbnail-wrap--with-left-highlighting-line");
    targetElem?.classList.remove("thumbnail-wrap--with-right-highlighting-line");
  }

  if (newDOMStringMap) {
    const areaQuarter = elementAreaQuarterDraggedOver.value || elementAreaQuarterTouchDraggedOver.value;
    const targetElem: HTMLElement = findElem(thumbnails, newDOMStringMap) || findElem(placeholders, newDOMStringMap);
    targetElem?.classList.add([1, 4].includes(areaQuarter) ? "thumbnail-wrap--with-left-highlighting-line" : "thumbnail-wrap--with-right-highlighting-line");
  }
}

watch(
  () => [elementDatasetDraggedOver.value, elementDatasetTouchDraggedOver.value, elementAreaQuarterDraggedOver.value, elementAreaQuarterTouchDraggedOver.value],
  (newValue, oldValue) => {
    toggleHighlightingLineClass(newValue[0] || newValue[1], oldValue[0] || oldValue[1]);
  }
);

// Toggle delete thumbnails mode ==============================================
const isDeleteThumbnailsMode = ref<boolean>(false);

// Upload Errors =====================================================
// it's needed to show the validation error msg
const tooSmallImagesArray = ref<Array<string>>([]);

const invalidFileTypesArray = ref<Array<string>>([]);

function addInvalidFileTypeError(fileName: string) {
  const fileExtension = fileName.split(".").pop();
  if (!invalidFileTypesArray.value.includes(fileExtension)) {
    invalidFileTypesArray.value.push(fileExtension);
  }
}

function clearErrors() {
  tooSmallImagesArray.value = [];
  invalidFileTypesArray.value = [];
}

// Count total media files and display warning if needed ======================
const MaxMediaCountWarning = 250;
const totalMediaFilesAmount = computed<number>(() =>
  collabLocationsStore.locations.reduce((acc, item) => {
    return (item.mediaIds?.length || 0) + acc;
  }, 0)
);

const domRefTooManyPhotosWarningNote = ref<HTMLElement | null>(null);

const tooManyPhotosProblemBadgeId = Symbol();

watch(
  () => [totalMediaFilesAmount.value, domRefTooManyPhotosWarningNote.value?.domRefRoot],
  async () => {
    await nextTick(); // await for "Too Many Photos?" note to render
    if (totalMediaFilesAmount.value > MaxMediaCountWarning && !checkIfBadgeExists(tooManyPhotosProblemBadgeId)) {
      // Log and add the badge
      getGlobalRemoteLogger().info(`TooManyPhotos shown: count=${totalMediaFilesAmount.value} for creator ${props.creatorId} collab ${props.collabInput.id}`, false, false, {
        assetCount: totalMediaFilesAmount.value,
        creatorId: props.creatorId,
        collabId: props.collabInput.id,
      });
      upsertBadge({
        id: tooManyPhotosProblemBadgeId,
        color: "yellow",
        isVisible: true,
        description: "Too many photos?",
        callback: async () => emit("goToCreateTab"),
        elemToScrollTo: domRefTooManyPhotosWarningNote.value?.domRefRoot,
      });
    } else if (checkIfBadgeExists(tooManyPhotosProblemBadgeId) && totalMediaFilesAmount.value <= MaxMediaCountWarning) {
      removeBadge(tooManyPhotosProblemBadgeId);
    }
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/hoverCircle";
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  &__title {
    margin-bottom: 5px;
  }

  &__subtitle-n-remove-mode-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__subtitle {
  }

  &__remove-mode-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .header {
    &__subtitle-n-remove-mode-btn {
      flex-direction: column;
      align-items: flex-start;
    }

    &__subtitle {
      margin-bottom: 15px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    &__subtitle-n-remove-mode-btn {
      flex-direction: column;
      align-items: flex-start;
    }

    &__subtitle {
      margin-bottom: 15px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    &__subtitle-n-remove-mode-btn {
      flex-direction: column;
      align-items: flex-start;
    }

    &__subtitle {
      margin-bottom: 15px;
    }
  }
}

// Upload button ==============================================================
.upload-btn {
  aspect-ratio: 4/3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(218, 218, 218, 1);
  user-select: none;
  cursor: pointer;
  transition: background-color 0.07s ease-in-out;

  &:hover {
    background: rgba(205, 205, 205, 1);
  }

  &__icon {
    margin-top: -2px;

    &::before {
      @include hoverCircleNotHovered;
    }
  }

  &:hover &__icon::before {
    @include hoverCircleHovered;
  }

  &__text {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
  }

  &:hover &__text {
    text-decoration: none;
  }
}

// Upload progress bar ========================================================
.upload-progress-bar {
  display: flex;

  &__text {
    margin-right: 10px;
  }

  &__scale-wrap {
    width: 150px;
    height: 7px;
    margin-top: 5px;
    border-radius: 100px;
    position: relative;
    color: rgba(91, 91, 91, 1);
    font: 14px/14px sans-serif;
    text-align: center;
    background: rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }

  &__scale {
    height: 7px;
    margin-bottom: 2px;
    border-radius: 1000px;
    inset: 0 auto auto 0;
    background: rgba(17, 134, 137, 1);
    transition: width 0.3s ease-in-out;
  }
}

// Thumbnail wrap =============================================================
.thumbnail-wrap {
  position: relative;
  z-index: 0;

  &--with-left-highlighting-line {
    z-index: 0;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto -9px;
      z-index: 1;
      background: rgba(17, 134, 137, 1);
    }
  }

  &--with-right-highlighting-line {
    z-index: 0;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 -8px auto auto;
      z-index: 1;
      background: rgba(17, 134, 137, 1);
    }
  }

  &--with-gray-overlay {
    z-index: 2;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 6px !important;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 1;
      background: rgba(200, 200, 200, 1);
      pointer-events: none;
    }
    &[data-cloned-element-for-photos-section-touch-drag-event] {
      &::after {
        display: none;
      }
    }
  }

  &__drag-n-drop-handle {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 7px auto auto 7px;
    z-index: 1;
    cursor: grab;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    transition: opacity 0.12s ease-in-out;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    :deep(*) {
      pointer-events: none;
    }
  }

  &__thumbnail {
    position: relative;
    z-index: 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .thumbnail-wrap {
    &--with-left-highlighting-line {
      &::before {
        left: -9px;
      }
    }

    &--with-right-highlighting-line {
      &::before {
        right: -8px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnail-wrap {
    &--with-left-highlighting-line {
      &::before {
        left: -7px;
      }
    }
    &--with-right-highlighting-line {
      &::before {
        right: -6px;
      }
    }
  }
}

// Thumbnails list ============================================================
.thumbnails-list {
  position: relative;
  z-index: 0;

  &__upload-photo-form {
    width: 100%;
    height: calc(100% + 60px);
    min-height: calc(100% + 60px);
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 1;
    opacity: 0;
  }

  &__upload-progress-bar {
    margin-bottom: 12px;
  }

  &__list-itself {
    width: calc(100% + 6px);
    margin: 0 0 0 -6px;
    display: flex;
    flex-wrap: wrap;
  }

  &__thumbnail-wrap {
    width: calc(100% / 7 - 12px);
    margin: 0 6px 12px;
    position: relative;
    z-index: 2;

    &--placeholder {
      :deep(.spinner-overlay__spinner) {
        display: none;
      }
    }

    &--invisible-for-clicks {
      pointer-events: none;
    }
  }

  &__thumbnail {
    &--pointer-events-none {
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .thumbnails-list {
    &__list-itself {
      width: calc(100% + 6px);
      margin: 0 0 0 -6px;
    }

    &__thumbnail-wrap {
      width: calc(100% / 4 - 12px);
      margin: 0 6px 12px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnails-list {
    &__list-itself {
      width: calc(100% + 6px);
      margin: 0 0 0 -6px;
    }

    &__thumbnail-wrap {
      width: calc(100% / 3 - 8px);
      margin: 0 4px 7px;
    }
  }
}

// Create tab =================================================================
.create-tab {
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__header {
    margin-bottom: 17px;
  }

  &__location-sections-list {
    padding: 0;
    margin: 0 0 23px;
    list-style: none;
  }

  &__location-section {
    padding: 10px 15px 15px;
    margin-bottom: 18px;
    border: 1px rgba(180, 180, 180, 1) dashed;
    border-radius: 6px;
    background: rgba(243, 243, 243, 1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__add-location-btn-n-stats {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
  }

  &__add-location-btn {
  }

  &__stats {
    font-weight: 400;
    text-align: right;
  }

  &__divider {
    margin-bottom: 26px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__validation-n-next-btn-section {
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    inset: auto 0 0 auto;
    z-index: 6;

    &::before {
      content: "";
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      position: absolute;
      inset: auto auto 0 -15px;
      z-index: -1;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }
  }

  &__validation {
    width: calc(100% - 160px);
    color: rgba(128, 0, 0, 1);
    font-weight: bold;
    white-space: normal;
    word-break: normal;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__next-btn {
    margin-left: auto;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .create-tab {
    &__location-sections-list {
      width: calc(100% + 40px);
      margin-left: -20px;
    }

    &__location-section {
      border-radius: 0;
    }

    &__validation-n-next-btn-section {
      padding-bottom: 75px;

      &::before {
        height: calc(100% + 70px);
      }
    }
  }
}
</style>
