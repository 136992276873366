export default {
  // Note: May want to add FormatCurrency in the future

  formatNumber(input: number): string {
    // Note: Probably add decimal places in the future
    if (input === 0) return "0";
    if (!input) return "";

    return input.toLocaleString();
  },
  abbreviatedFormat(input: number): string {
    if (input === 0) return "0";
    if (!input) return "";
    // Add some formatting smarts about how much precision to use
    if (input >= 10000000) {
      return this.round(input / 1000000, 0) + "M";
    }
    if (input >= 1000000) {
      return this.round(input / 1000000, 1) + "M";
    } else if (input >= 10000) {
      return this.round(input / 1000, 0) + "K";
    } else if (input >= 1000) {
      return this.round(input / 1000, 1) + "K";
    }
    return input.toString();
  },
  round(value, precision) {
    // https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  },
};
