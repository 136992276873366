<template>
  <div class="social-links">
    <div
      class="social-links__in-wrap"
      :style="{
        marginRight: `-${iconsGap / 2}px`,
        marginLeft: `-${iconsGap / 2}px`,
      }"
    >
      <SocialButton
        v-if="socialFields.instagramHandle"
        class="social-links__link"
        :instagramHandle="socialFields.instagramHandle"
        :isOnlyCopy="isOnlyCopy"
        :isCopyIconVisible="showCopyIcon"
        :isOnlyIcon="!isFullSizeIcons"
        :iconSize="iconsSize"
        :iconColor="iconsColor"
        :style="{ margin: `${iconsGap / 2}px` }"
      />

      <SocialButton
        v-if="socialFields.tikTokHandle"
        class="social-links__link"
        :tiktokHandle="socialFields.tikTokHandle"
        :isOnlyCopy="isOnlyCopy"
        :isCopyIconVisible="showCopyIcon"
        :isOnlyIcon="!isFullSizeIcons"
        :iconSize="iconsSize"
        :iconColor="iconsColor"
        :style="{ margin: `${iconsGap / 2}px` }"
      />

      <SocialButton
        v-if="socialFields.facebookURL"
        class="social-links__link"
        :facebookUrl="socialFields.facebookURL"
        :isOnlyCopy="isOnlyCopy"
        :isCopyIconVisible="showCopyIcon"
        :isOnlyIcon="!isFullSizeIcons"
        :iconSize="iconsSize"
        :iconColor="iconsColor"
        :style="{ margin: `${iconsGap / 2}px` }"
      />

      <SocialButton
        v-if="socialFields.youtubeURL"
        class="social-links__link"
        :youtubeUrl="socialFields.youtubeURL"
        :isOnlyCopy="isOnlyCopy"
        :isCopyIconVisible="showCopyIcon"
        :isOnlyIcon="!isFullSizeIcons"
        :iconSize="iconsSize"
        :iconColor="iconsColor"
        :style="{ margin: `${iconsGap / 2}px` }"
      />

      <SocialButton
        v-if="socialFields.twitterHandle"
        class="social-links__link"
        :twitterHandle="socialFields.twitterHandle"
        :isOnlyCopy="isOnlyCopy"
        :isCopyIconVisible="showCopyIcon"
        :isOnlyIcon="!isFullSizeIcons"
        :iconSize="iconsSize"
        :iconColor="iconsColor"
        :style="{ margin: `${iconsGap / 2}px` }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SocialMediaFields } from "@contracts/creatorPublicProfileFields";
import CopyText from "@components/CopyText.vue";
import SocialButton from "@components/ui/SocialButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "SocialMediaLinksBar",

  components: {
    IconEmbedded,
    SocialButton,
    CopyText,
  },

  props: {
    socialFields: { type: Object as () => SocialMediaFields, required: true },
    showCopyIcon: { type: Boolean, default: false },
    isOnlyCopy: { type: Boolean, default: false },
    isFullSizeIcons: { type: Boolean, default: true },
    iconsSize: { type: Number, default: 19 },
    iconsGap: { type: Number, default: 10 },
    iconsColor: { type: String, default: "rgba(140, 140, 140, 1)" },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Social links ===============================================================
.social-links {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &__in-wrap {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__link {
  }
}
</style>
