import { CreatorPublicProfileFields, SocialMediaFields } from "@contracts/creatorPublicProfileFields";

export interface ICreatorProfileViewModel extends SocialMediaFields {
  authNameId: string | null;
  uriKey: string | null;
  sherpaId: string | null;
  firstName: string | null;
  createdOn: string | null;
  tileImageLocation: string | null;
  shortBlurb: string | null;
  orgSiteUrl: string | null;
  orgName: string | null;
  featuredMedia: Array<string>;

  // Adding this to force this object to be different from CreatorPublicProfileFields
  // so typescript can give warnings if we're using the wrong one
  _fakeField: string | null;
}

export function CreateProfileViewModelFromProfileFields(userProfile: CreatorPublicProfileFields): ICreatorProfileViewModel {
  const newViewModel: ICreatorProfileViewModel = {
    authNameId: userProfile.authNameId,
    uriKey: userProfile.uriKey,
    sherpaId: userProfile.sherpaId,
    firstName: userProfile.firstName,
    createdOn: userProfile.createdOn,
    tileImageLocation: userProfile.tileImageLocation,
    shortBlurb: userProfile.shortBlurb,
    orgSiteUrl: userProfile.orgSiteUrl,
    orgName: userProfile.orgName,
    featuredMedia: userProfile.featuredMedia,
    twitterHandle: userProfile.twitterHandle,
    instagramHandle: userProfile.instagramHandle,
    facebookURL: userProfile.facebookURL,
    youtubeURL: userProfile.youtubeURL,
    tikTokHandle: userProfile.tikTokHandle,
    _fakeField: null,
  };

  return newViewModel;
}

export function CopyProfileViewModelToUserProfile(viewModel: ICreatorProfileViewModel, userProfile: CreatorPublicProfileFields): void {
  userProfile.authNameId = viewModel.authNameId;
  userProfile.uriKey = viewModel.uriKey;
  userProfile.sherpaId = viewModel.sherpaId;
  userProfile.firstName = viewModel.firstName;
  userProfile.createdOn = viewModel.createdOn;
  userProfile.tileImageLocation = viewModel.tileImageLocation;
  userProfile.shortBlurb = viewModel.shortBlurb;
  userProfile.orgSiteUrl = viewModel.orgSiteUrl;
  userProfile.orgName = viewModel.orgName;
  userProfile.featuredMedia = viewModel.featuredMedia;
  userProfile.twitterHandle = viewModel.twitterHandle;
  userProfile.instagramHandle = viewModel.instagramHandle;
  userProfile.facebookURL = viewModel.facebookURL;
  userProfile.youtubeURL = viewModel.youtubeURL;
  userProfile.tikTokHandle = viewModel.tikTokHandle;
}
