<template>
  <div class="download-form">
    <div class="download-form__caption-n-media">
      <!-- Caption section -->
      <div class="caption-section download-form__caption-section">
        <div class="global-h4 caption-section__title">Caption</div>
        <div class="caption-section__text-block">
          {{ shareStopSummaryStore.editedPost.caption }}
        </div>

        <CopyCaptionButton class="caption-section__copy-button" :text="shareStopSummaryStore.editedPost.caption" />

        <!-- Tag creators buttons -->
        <div v-if="shareStopSummaryStore.adventureCreatorSummariesWithSocials.length" class="tag-creators-buttons caption-section__tag-creators-section">
          <div class="global-h4 tag-creators-buttons__title">Tag the creators</div>

          <!-- Creator snippet -->
          <div class="creator-snippet tag-creators-buttons__creator-snippet" v-for="creator in shareStopSummaryStore.adventureCreatorSummariesWithSocials" :key="creator.creatorId">
            <AvatarWithFallback class="creator-snippet__avatar" :src="creator.profilePhoto" :alt="creator.displayName" />
            <div class="creator-snippet__buttons-side">
              <div class="creator-snippet__title">{{ creator.displayName }}</div>
              <ul class="creator-snippet__buttons-list">
                <template v-for="socialField in Object.keys(creator.socialFields)" :key="socialField">
                  <SocialButton
                    v-if="socialField === 'facebookURL' && creator.socialFields.facebookURL"
                    class="creator-snippet__button"
                    :facebook-url="creator.socialFields.facebookURL"
                    :is-with-text="false"
                    :is-only-copy="true"
                  />
                  <SocialButton
                    v-else-if="socialField === 'instagramHandle' && creator.socialFields.instagramHandle"
                    class="creator-snippet__button"
                    :instagram-handle="creator.socialFields.instagramHandle"
                    :is-with-text="false"
                    :is-only-copy="true"
                  />
                  <SocialButton
                    v-else-if="socialField === 'tiktokHandle' && creator.socialFields.tikTokHandle"
                    class="creator-snippet__button"
                    :tiktok-handle="creator.socialFields.tikTokHandle"
                    :is-with-text="false"
                    :is-only-copy="true"
                  />
                  <SocialButton
                    v-else-if="socialField === 'twitterHandle' && creator.socialFields.twitterHandle"
                    class="creator-snippet__button"
                    :twitter-handle="creator.socialFields.twitterHandle"
                    :is-with-text="false"
                    :is-only-copy="true"
                  />
                  <SocialButton
                    v-else-if="socialField === 'youtubeURL' && creator.socialFields.youtubeURL"
                    class="creator-snippet__button"
                    :youtube-url="creator.socialFields.youtubeURL"
                    :is-with-text="false"
                    :is-only-copy="true"
                  />
                </template>
              </ul>
            </div>
          </div>
          <!-- / Creator snippet -->
        </div>
        <!-- / Tag creators buttons -->
      </div>
      <!-- / Caption section -->

      <!-- Media section -->
      <div class="media-section download-form__media-section">
        <div class="global-h4 media-section__title">
          Download media <span style="color: #9e9e9e">{{ shareStopSummaryStore.editedPost.assetIds.length }}</span>
        </div>
        <ul class="media-section__images-list">
          <li class="media-section__image-wrap" v-for="mediaUrl in shareStopSummaryStore.editedPost.assetIds" :key="mediaUrl">
            <MediaThumbnail class="media-section__image" :media-url="mediaUrl" />
          </li>
        </ul>
        <SrpButton class="media-section__button" @click="handleDownloadRequest" :is-disabled="isRequestingDownloadId">
          Download ZIP
          <template #iconRight>
            <IconEmbedded v-if="isRequestingDownloadId" name="loader_3" color="rgba(255, 255, 255, 0.5)" :size="25" />
            <IconEmbedded v-else name="download_2" color="rgba(255, 255, 255, 0.5)" :size="25" />
          </template>
        </SrpButton>
      </div>
      <!-- / Media section -->
    </div>

    <div class="download-form__footer">
      <!-- Is saved msg -->
      <div
        :class="{
          'is-saved-msg': true,
          'download-form__is-saved-msg': true,
        }"
      >
        <div
          :class="{
            'is-saved-msg__check-icon': true,
            'is-saved-msg__check-icon--green': isSavedMsgVisible,
            'is-saved-msg__check-icon--spin-animated-icon': !isSavedMsgVisible,
          }"
        >
          <IconEmbedded v-if="isSavedMsgVisible" :name="isSavedMsgVisible ? 'check_3-5' : 'loader_3'" :size="isSavedMsgVisible ? 25 : 23" />
        </div>
        <div class="global-h2 is-saved-msg__text">
          <template v-if="isSavedMsgVisible">Post saved</template>
          <template v-else>Saving<EllipsisAnimated /></template>
        </div>
      </div>
      <!-- / Is saved msg -->

      <div class="download-form__footer-links">
        <LinkWithIcon class="download-form__footer-link" color="blue" @click="resetStateAndCreateNewPost">
          <template #icon><IconEmbedded name="plus_2-5" :size="24" /></template>
          <span>Create new post</span>
        </LinkWithIcon>

        <LinkWithIcon class="download-form__footer-link" :to="{ name: 'SavedPosts', params: { pageId: $route.params.pageId } }" tag="RouterLink">
          <template #icon><IconEmbedded name="checklist_2" :size="25" /></template>
          <span>Saved posts</span
          ><span style="color: #888; text-decoration: none !important"> &nbsp;{{ shareStopSummaryStore.allPosts.length ? `(${shareStopSummaryStore.allPosts.length})` : "" }}</span>
        </LinkWithIcon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import FileDownload from "@logic/FileDownload";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
const shareStopSummaryStore = useShareStopSummaryStore();

// Components
import CopyCaptionButton from "@views/Community/ShareStopSummaryV2/CopyCaptionButton.vue";
import EllipsisAnimated from "@components/ui/EllipsisAnimated.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import MediaThumbnail from "@components/MediaThumbnail.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SocialButton from "@components/ui/SocialButton.vue";
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  text?: string;
}
const props = withDefaults(defineProps<Props>(), {
  text: "",
});

const emit = defineEmits<{
  (e: "goToNextSlide"): void;
}>();

// Run saved post msg animation ===============================================
const isSavedMsgVisible = ref(false);

onMounted(() => {
  setTimeout(() => (isSavedMsgVisible.value = true), 1900);
});

// ============================================================================
async function resetStateAndCreateNewPost() {
  shareStopSummaryStore.resetEditedPost();
  shareStopSummaryStore.activeStep = 1;

  await router.push({
    name: "ShareStopSummaryV2",
    params: { pageId: route.params.pageId },
  });
}

// Download the media =========================================================
const contentBaseUri = globalThis.Bootstrap.Config.contentBlobBaseUri;
const isRequestingDownloadId = ref(false);

async function handleDownloadRequest() {
  let downloadId = "";

  isRequestingDownloadId.value = true;
  if (shareStopSummaryStore.editedPost.zippedDownloadId) {
    downloadId = shareStopSummaryStore.editedPost.zippedDownloadId;
  } else {
    downloadId = await shareStopSummaryStore.requestDownloadId(shareStopSummaryStore.editedPost.customerId, shareStopSummaryStore.editedPost.id);
  }
  isRequestingDownloadId.value = false;

  FileDownload.downloadFileByURI(`${contentBaseUri}/${downloadId}`);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";
@import "@/scss/mixins/animations/gradient-spin-black";

// Creator snippet ============================================================
.creator-snippet {
  display: flex;

  &__avatar {
    width: 54px;
    height: 54px;
    margin-right: 13px;
  }

  &__buttons-side {
  }

  &__title {
    margin: -2px 0 5px;
    font: bold 14px/19px sans-serif;
  }

  &__buttons-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__button {
    width: 55px;
    margin: 0 7px 7px 0;

    :deep(.social-button__a) {
      padding: 0;
    }
  }
}

// Tag creators buttons =======================================================
.tag-creators-buttons {
  &__title {
    margin-bottom: 17px;
    font-weight: 500;
  }

  &__creator-snippet {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Is saved msg ===============================================================
.is-saved-msg {
  display: flex;
  align-items: center;

  &__check-icon {
    width: 38px;
    min-width: 38px;
    height: 38px;
    margin-right: 13px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2px;
    transform: scale(1);
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    transition-delay: 0.2s;

    &--green {
      color: #518345;
      background: #dcf5d6;
    }

    &--spin-animated-icon {
      &::before {
        @include gradientSpinBlack;
        opacity: 0.7;
      }
    }
  }

  &__text {
    font-weight: 400;
  }
}

// Caption section ============================================================
.caption-section {
  &__title {
    margin-bottom: 11px;
    font-weight: 600;
  }

  &__text-block {
    padding: 15px 23px 17px;
    margin-bottom: 16px;
    border-radius: 6px;
    color: #5b5b5b;
    font-family: sans-serif;
    background: rgba(0, 0, 0, 0.05);
  }

  &__copy-button {
    margin-bottom: 35px;
  }

  &__tag-creators-section {
  }
}

// Media section ==============================================================
.media-section {
  &__title {
    margin-bottom: 11px;
    font-weight: 600;
  }

  &__images-list {
    padding: 17px 20px;
    margin: 0 0 16px 0;
    border-radius: 6px;
    column-gap: 9px;
    column-count: 4;
    list-style: none;
    background: rgba(0, 0, 0, 0.05);
  }

  &__image-wrap {
    margin-bottom: 9px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    break-inside: avoid-column;
    page-break-inside: avoid;
  }

  &__image {
    width: 100%;
  }

  &__button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .media-section {
    &__images-list {
      column-count: 3;
    }
  }
}

// Download form ==============================================================
.download-form {
  &__caption-n-media {
    padding-bottom: 39px;
    margin-bottom: 29px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: space-between;
  }

  &__caption-section {
    width: calc(50% - 14px);
  }

  &__media-section {
    width: calc(50% - 13px);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__is-saved-msg {
  }

  &__footer-links {
    display: flex;
    align-items: center;
  }

  &__footer-link {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .download-form {
    &__caption-section {
      width: calc(50% - 12px);
    }

    &__media-section {
      width: calc(50% - 12px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .download-form {
    &__caption-section {
      width: calc(50% - 11px);
    }

    &__media-section {
      width: calc(50% - 11px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .download-form {
    &__caption-n-media {
      padding-bottom: 30px;
      margin-bottom: 20px;
      flex-direction: column;
    }

    &__caption-section {
      width: 100%;
      margin-bottom: 45px;
    }

    &__media-section {
      width: 100%;
    }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }

    &__is-saved-msg {
      margin-bottom: 25px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .download-form {
    &__caption-n-media {
      padding-bottom: 30px;
      margin-bottom: 20px;
      flex-direction: column;
    }

    &__caption-section {
      width: 100%;
      margin-bottom: 45px;
    }

    &__media-section {
      width: 100%;
    }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }

    &__is-saved-msg {
      margin-bottom: 25px;
    }
  }
}
</style>
