<template>
  <div class="social-media-inputs">
    <ul class="social-media-inputs__inputs-list">
      <!-- Input section -->
      <li v-if="!hiddenFields.includes('instagramHandle')" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("instagramHandle") }}
        </h5>
        <div class="input-section__input-wrap">
          <input
            class="global-text-input input-section__input"
            type="text"
            v-model="props.socialFields.instagramHandle"
            placeholder="YourHandleHere"
            @change="instaHandleChanged"
            autocorrect="off"
            autocapitalize="none"
          />
        </div>
        <div v-if="props.socialFields.instagramHandle" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="`https://instagram.com/${props.socialFields.instagramHandle}/`" target="_blank">
            <span class="test-link__text">https://instagram.com/{{ props.socialFields.instagramHandle }}/</span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="!hiddenFields.includes('orgSiteUrl') && props.isPersonalWebsiteFieldsVisible" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("orgSiteUrl") }}
        </h5>
        <div class="input-section__input-wrap">
          <input class="global-text-input input-section__input" type="text" v-model="props.socialFields.orgSiteUrl" placeholder="https://www.yourwebsite.com" autocorrect="off" autocapitalize="none" />
        </div>
        <div v-if="props.socialFields.orgSiteUrl" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="'//' + props.socialFields.orgSiteUrl.replace(/(^\w+:|^)\/\//, '')" target="_blank">
            <span class="test-link__text">
              {{ props.socialFields.orgSiteUrl }}
            </span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="props.isPersonalWebsiteFieldsVisible && props.socialFields.orgSiteUrl?.length > 0" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("orgName") }}
        </h5>
        <div class="input-section__input-wrap">
          <input class="global-text-input input-section__input" type="text" v-model="props.socialFields.orgName" placeholder="My Travel Blog" />
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="!hiddenFields.includes('facebookURL')" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("facebookURL") }}
        </h5>
        <div class="input-section__input-wrap">
          <input
            class="global-text-input input-section__input"
            type="text"
            v-model="props.socialFields.facebookURL"
            placeholder="https://www.facebook.com/yourusername"
            autocorrect="off"
            autocapitalize="none"
          />
        </div>
        <div v-if="props.socialFields.facebookURL" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="'//' + props.socialFields.facebookURL.replace(/(^\w+:|^)\/\//, '')" target="_blank">
            <span class="test-link__text">{{ props.socialFields.facebookURL }}</span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="!hiddenFields.includes('twitterHandle')" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("twitterHandle") }}
        </h5>
        <div class="input-section__input-wrap input-section__input-wrap--with-at-symbol">
          <input
            class="global-text-input input-section__input input-section__input--bigger-left-padding"
            type="text"
            v-model="props.socialFields.twitterHandle"
            placeholder="YourUsername"
            autocorrect="off"
            autocapitalize="none"
          />
        </div>
        <div v-if="props.socialFields.twitterHandle" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="'//twitter.com/' + props.socialFields.twitterHandle" target="_blank">
            <span class="test-link__text">https://twitter.com/{{ props.socialFields.twitterHandle }}</span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="!hiddenFields.includes('youtubeURL')" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("youtubeURL") }}
        </h5>
        <div class="input-section__input-wrap">
          <input
            class="global-text-input input-section__input"
            type="text"
            v-model="props.socialFields.youtubeURL"
            placeholder="https://www.youtube.com/channel/UCUb1D3opuaJh64kFaXBn7Ow"
            autocorrect="off"
            autocapitalize="none"
          />
        </div>
        <div v-if="props.socialFields.youtubeURL" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="'//' + props.socialFields.youtubeURL.replace(/(^\w+:|^)\/\//, '')" target="_blank">
            <span class="test-link__text">{{ props.socialFields.youtubeURL }}</span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->

      <!-- Input section -->
      <li v-if="!hiddenFields.includes('tikTokHandle')" class="input-section social-media-inputs__input-section">
        <h5 class="global-h5 input-section__title">
          {{ convertFieldNameToHumanReadableName("tikTokHandle") }}
        </h5>
        <div class="input-section__input-wrap input-section__input-wrap--with-at-symbol">
          <input
            class="global-text-input input-section__input input-section__input--bigger-left-padding"
            type="text"
            v-model="props.socialFields.tikTokHandle"
            placeholder="YourUsername"
            autocorrect="off"
            autocapitalize="none"
          />
        </div>
        <div v-if="props.socialFields.tikTokHandle" class="input-section__test-link-line">
          Test Link:
          <!-- Test link -->
          <a class="test-link input-section__test-link" :href="'//tiktok.com/@' + props.socialFields.tikTokHandle" target="_blank">
            <span class="test-link__text">https://tiktok.com/@{{ props.socialFields.tikTokHandle }}</span>
            <IconEmbedded class="test-link__outer-icon" name="external-link_2-5" :size="15" color="rgba(0, 0, 0, 0.3)" style="position: relative; top: 2px" />
          </a>
          <!-- / Test link -->
        </div>
      </li>
      <!-- / Input section -->
    </ul>

    <!-- Add more links -->
    <div v-if="hiddenFieldsLinks.length" class="add-more-links social-media-inputs__add-more-links">
      <span class="add-more-links__title">Add more:</span>
      <ul class="add-more-links__links-list">
        <li class="add-more-links__add-link" v-for="field in hiddenFieldsLinks" :key="field" @click="hiddenFields = hiddenFields.filter(f => f !== field)">
          {{ convertFieldNameToHumanReadableName(field) }}
        </li>
      </ul>
    </div>
    <!-- / Add more links -->
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import SocialFieldValidator from "@logic/SocialFieldValidator";

// Types
import { SocialMediaFields } from "@contracts/creatorPublicProfileFields";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

interface SocialMediaFilesAndWebsite extends SocialMediaFields {
  orgSiteUrl?: string | null;
  orgName?: string | null;
}

const props = withDefaults(
  defineProps<{
    socialFields: SocialMediaFilesAndWebsite;
    isPersonalWebsiteFieldsVisible?: boolean;
  }>(),
  {
    socialFields: null,
    isPersonalWebsiteFieldsVisible: false,
  }
);

const emit = defineEmits<{
  (e: "update:personalWebsite", value: string): void;
}>();

function instaHandleChanged() {
  props.socialFields.instagramHandle = SocialFieldValidator.parseInstagramHandle(props.socialFields.instagramHandle);
}

// Toggle fields ==============================================================
const allFields: Array<keyof SocialMediaFilesAndWebsite> = ["twitterHandle", "instagramHandle", "facebookURL", "youtubeURL", "tikTokHandle", "orgSiteUrl", "orgName"];
const hiddenFields = ref<Array<keyof SocialMediaFilesAndWebsite>>([]);
/* Filter out the 'orgName' field from links because it being added automatically when 'orgSiteUrl' is not empty
 * and also remove the 'orgSiteUrl' field if props.isPersonalWebsiteFieldsVisible === false
 */
const hiddenFieldsLinks = computed<Array<keyof SocialMediaFilesAndWebsite>>(() => {
  let _hiddenFields = hiddenFields.value.filter(f => f !== "orgName");
  if (!props.isPersonalWebsiteFieldsVisible) _hiddenFields = _hiddenFields.filter(f => f !== "orgSiteUrl");
  return _hiddenFields;
});

// Make sure that at least 2 fields are always visible
onMounted(() => {
  const visibleFields = allFields.filter(f => Boolean(props.socialFields[f]));

  if (visibleFields.length === 0) {
    visibleFields.push("orgSiteUrl", "instagramHandle");
  } else if (visibleFields.length === 1) {
    if (visibleFields[0] === "instagramHandle") visibleFields.push("orgSiteUrl");
    else if (visibleFields[0] === "orgSiteUrl") visibleFields.push("instagramHandle");
    else visibleFields.push("instagramHandle");
  }

  hiddenFields.value = allFields.filter(f => !visibleFields.includes(f)).sort((a, b) => (a > b ? 1 : -1));
});

// Convert field name to human readable name ==================================
type FieldName = "orgSiteUrl" | "orgName" | "twitterHandle" | "instagramHandle" | "facebookURL" | "youtubeURL" | "tikTokHandle";
function convertFieldNameToHumanReadableName(fieldName: FieldName): string {
  return {
    orgSiteUrl: "Personal Website Url",
    orgName: "Personal Website Name",
    twitterHandle: "Twitter Handle",
    instagramHandle: "Instagram Username",
    facebookURL: "Facebook Url",
    youtubeURL: "YouTube Url",
    tikTokHandle: "TikTok Handle",
  }[fieldName];
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Test link ==================================================================
.test-link {
  &__text {
    color: #118689;
    text-decoration: underline;
    text-decoration-color: rgba(#118689, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &:hover &__text {
    text-decoration: none;
  }

  &__outer-icon {
    padding-left: 3px;
  }
}

// Input section ==============================================================
.input-section {
  &__title {
    margin-bottom: 6px;
  }

  &__input-wrap {
    margin-bottom: 5px;
    position: relative;

    &--with-at-symbol {
      &::before {
        content: "@";
        width: 15px;
        height: 15px;
        position: absolute;
        inset: 12px auto auto 10px;
        color: #5b5b5b;
        font: 14px/14px sans-serif !important;
        pointer-events: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    font: 14px/14px sans-serif !important;

    &--bigger-left-padding {
      padding-left: 28px !important;
    }
  }

  &__test-link-line {
    color: #5b5b5b;
    font: 14px/14px sans-serif !important;
  }

  &__test-link {
  }
}

// Add more links =============================================================
.add-more-links {
  display: flex;
  color: #5b5b5b;
  font: 14px/18px sans-serif;

  &__title {
    margin-right: 9px;
    font-weight: bold;
  }

  &__links-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__add-link {
    margin-right: 9px;
    color: #118689;
    text-decoration: underline;
    text-decoration-color: #118689;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .add-more-links {
    flex-direction: column;
  }
}

// Social media inputs ========================================================
.social-media-inputs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__inputs-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }

  &__input-section {
    width: calc(50% - 14px);
    margin: 0 27px 18px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &__add-more-links {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .social-media-inputs {
    flex-direction: column;

    &__input-section {
      width: 100%;
      margin: 0 0 18px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .social-media-inputs {
    flex-direction: column;

    &__input-section {
      width: 100%;
      margin: 0 0 18px 0;
    }
  }
}
</style>
