<template>
  <div
    :class="{
      'copy-button': true,
      'copy-button--bigger-with-left-padding': !showCopied,
      'copy-button--disabled': showCopied,
    }"
    @click="handleClick"
  >
    <IconEmbedded :name="showCopied ? 'check_3-5' : 'caret-right_2'" :size="showCopied ? 25 : 35" color="rgba(255, 255, 255, 1)" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const emit = defineEmits<{
  (e: "onCopy"): void;
}>();

// Handle copying to clipboard ================================================
const showCopied = ref(false);
function handleClick() {
  emit("onCopy");

  showCopied.value = true;
  setTimeout(() => (showCopied.value = false), 1000);
}
</script>

<style scoped lang="scss">
// Copy button ================================================================
.copy-button {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;

  &--bigger-with-left-padding {
    font-size: 30px;
    line-height: 30px;
    padding-left: 2px;
  }

  &--disabled {
    pointer-events: none;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: #058587;
    transition:
      width 0.1s ease-in-out,
      height 0.1s ease-in-out,
      inset 0.1s ease-in-out,
      background-color 0.1s ease-in-out;
  }

  &:hover::after {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    inset: -2px auto auto -2px;
    background: #102f41;
  }
}
</style>
