<template>
  <div
    :class="{
      'close-button': true,
      // color
      'close-button--gray': props.color === 'gray',
      'close-button--black': props.color === 'black',
      'close-button--red': props.color === 'red',
      // size
      'close-button--tiny': props.size === 'tiny',
      'close-button--small': props.size === 'small',
      'close-button--medium': props.size === 'medium',
      'close-button--large': props.size === 'large',
    }"
  >
    <IconEmbedded v-if="props.icon === 'cross'" class="close-button__icon" name="remove_3" :size="{ tiny: 18, small: 20, medium: 24, large: 24 }[props.size]" />
    <IconEmbedded v-else-if="props.icon === 'trashcan'" class="close-button__icon" name="trashcan-alt_2-5" :size="{ tiny: 17, small: 19, medium: 23, large: 23 }[props.size]" />
  </div>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    color?: "gray" | "black" | "red";
    size?: "tiny" | "small" | "medium" | "large";
    icon?: "cross" | "trashcan";
  }>(),
  {
    color: "gray",
    size: "medium",
    icon: "cross",
  }
);
</script>

<style scoped lang="scss">
.close-button {
  width: 37px;
  max-width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  user-select: none;

  // size
  &--tiny {
    width: 27px;
    max-width: 27px;
    height: 27px;
  }
  &--small {
  }

  &__icon {
    :deep(svg) {
      transition: fill 0.05s ease-in-out;
    }
  }

  &:hover &__icon {
    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(231, 237, 240, 1);
    transition:
      width 0.05s ease-in-out,
      height 0.05s ease-in-out,
      inset 0.05s ease-in-out,
      background 0.05s ease-in-out;
  }

  &:hover::before {
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    inset: -3px auto auto -3px;
    background: rgba(236, 86, 59, 1);
  }

  // color
  &--gray {
    :deep(svg) {
      fill: rgba(23, 37, 45, 0.5);
    }
  }
  &--gray:hover::before {
  }
  &--gray::before {
  }

  &--black {
    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &--black::before {
    background: rgba(0, 0, 0, 1);
  }
  &--black:hover::before {
  }

  &--red {
    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &--red::before {
    background: rgba(236, 86, 59, 1);
  }
  &--red:hover::before {
  }

  &--left-aligned {
    inset: calc(50% - 18px) auto auto -18px;
  }
}
</style>
