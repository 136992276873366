<template>
  <div
    :class="{
      'collab-suggestion-snippet': true,
      'collab-suggestion-snippet--expanded': isExpanded,
    }"
  >
    <h3 class="global-h3 collab-suggestion-snippet__title">{{ props.collabSuggestion.title || "Creator Visit Idea" }}</h3>

    <!-- Description snippet -->
    <div class="description-snippet collab-suggestion-snippet__description-snippet">
      <img class="description-snippet__icon" :src="getThemeIcon(convertThemeFormat(props.collabSuggestion.theme))" :alt="getThemeTypeName(convertThemeFormat(props.collabSuggestion.theme))" />

      <div class="description-snippet__right-col">
        <!--<h6 class="global-h6 description-snippet__title">Visit Focus</h6>-->
        <div class="description-snippet__description">
          {{ props.collabSuggestion.visitFocus }}
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <div class="collab-suggestion-snippet__divider"></div>

    <!-- Description snippet -->
    <div v-if="props.collabSuggestion?.requiredLocations?.length" class="description-snippet collab-suggestion-snippet__description-snippet">
      <div class="description-snippet__right-col">
        <h6 class="global-h6 description-snippet__title">Where to Go!</h6>
        <div class="description-snippet__description">
          <ul class="global-ul">
            <li v-for="location in props.collabSuggestion.requiredLocations.split(/\n/gim)" :key="location">
              {{ location }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <!-- Description snippet -->
    <div
      v-if="props.collabSuggestion?.suggestionLocations?.length"
      class="description-snippet collab-suggestion-snippet__description-snippet collab-suggestion-snippet__description-snippet--no-bottom-margin"
    >
      <div class="description-snippet__right-col">
        <h6 class="global-h6 description-snippet__title">Other Suggested Locations</h6>
        <div class="description-snippet__description">
          <ul class="global-ul">
            <li v-for="location in props.collabSuggestion.suggestionLocations.split(/\n/gim)" :key="location">
              {{ location }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- / Description snippet -->

    <!-- Bottom panel -->
    <div
      :class="{
        'bottom-panel': true,
        'bottom-panel--with-no-bg-gradient': isExpanded,
        'collab-suggestion-snippet__floating-bottom-panel': true,
      }"
    >
      <div class="bottom-panel__left-side">
        <LinkWithIcon class="bottom-panel__show-more-btn" isDottedUnderline iconInset="1px auto auto 3px" @click="isExpanded = !isExpanded">
          <template v-if="isExpanded" #icon><IconEmbedded name="caret-top_4" :size="13" /></template>
          <template v-else #icon><IconEmbedded name="caret-bottom_4" :size="13" /></template>
          <span>{{ isExpanded ? "Show less" : "Show more" }}</span>
        </LinkWithIcon>
      </div>

      <div class="bottom-panel__right-side">
        <SrpButton size="small" fill="outlined" @click="emit('getStarted')">Get Started</SrpButton>
        <SrpButton color="gray" fill="outlined" size="small" style="width: 48px" @click="emit('dislike')">
          <template #icon><IconEmbedded name="dislike_2" /></template>
        </SrpButton>
      </div>
    </div>
    <!-- / Bottom panel -->
  </div>
</template>

<script setup lang="ts">
import { getThemeIcon, getThemeTypeName } from "@helpers/ThemeTypeHelper.js";
import { ref } from "vue";
import { themeTypes } from "@contracts/collab";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CollabSuggestion } from "@contracts/collabSuggestions";
import { ThemeType } from "@contracts/collab";

const props = withDefaults(
  defineProps<{
    collabSuggestion: CollabSuggestion | null;
  }>(),
  {
    collabSuggestion: null,
  }
);

const emit = defineEmits<{
  (e: "getStarted"): void;
  (e: "dislike"): void;
}>();

// Convert BE theme format ====================================================
function convertThemeFormat(theme: string): ThemeType {
  if (!theme || !themeTypes.includes(theme as ThemeType)) {
    return "anyone";
  }
  return theme as ThemeType;
}

// Expand/collapse ============================================================
const isExpanded = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Description snippet ========================================================
.description-snippet {
  display: flex;
  align-items: flex-start;

  &__icon {
    width: 35px;
    margin-right: 15px;
  }

  &__right-col {
  }

  &__title {
    margin-bottom: 7px;
  }

  &__description {
  }
}

// Bottom panel ===============================================================
.bottom-panel {
  padding: 15px 22px 15px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 110px;
    border-radius: 6px;
    position: absolute;
    inset: auto auto 0 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    transition: background 0.15s ease-in-out;
  }

  &--with-no-bg-gradient {
    &::before {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__left-side {
    flex-grow: 1;
  }

  &__show-more-btn {
    margin-left: -7px;
  }

  &__right-side {
    gap: 12px;
    display: flex;
  }

  &__get-started-btn {
  }

  &__thumbs-down-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .bottom-panel {
    padding: 15px 18px 15px;
  }
}

// Collab suggestion snippet ==================================================
.collab-suggestion-snippet {
  max-height: 400px;
  padding: 18px 22px 70px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;
  transition: max-height 0.15s ease-in-out;

  &--expanded {
    max-height: 1000px;
  }

  &__title {
    margin-bottom: 14px;
  }

  &__description-snippet {
    margin-bottom: 18px;

    &--no-bottom-margin {
      margin-bottom: 0;
    }
  }

  &__divider {
    margin-bottom: 20px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
  }

  &__floating-bottom-panel {
    width: 100%;
    position: absolute;
    inset: auto auto 0 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-suggestion-snippet {
    max-height: 300px;

    &--expanded {
      max-height: 1000px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-suggestion-snippet {
    max-height: 265px;
    padding: 14px 18px 66px;

    &--expanded {
      max-height: 1000px;
    }
  }
}
</style>
