<template>
  <div>
    <button :class="buttonClasses" @click="showModal = true">Upload your Photos</button>

    <SrpModal v-model:isVisible="showModal" :isClosable="false">
      <template #header><h2 class="global-h2">Upload your Photos</h2></template>
      <template #content>
        <div style="margin: 10px 0">
          <!--NOTE: :img="[]" is required or the upload section won't show, should make that cleaner at some point-->
          <UploadPhotoForm :img="[]" :showClose="false" :autoSelect="false" :showRemove="false" @imageUploadedToServer="onImageUploadedToServer" />
        </div>
      </template>
      <template #footer>
        <SrpButton @click="doneUploading" fill="outlined">Done</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { UploadedImage } from "@contracts/uploadedImage";
import UploadPhotoForm from "./UploadPhotoForm.vue";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";

export default defineComponent({
  name: "UploadPhotosNonAdventure",

  components: {
    SrpButton,
    SrpModal,
    UploadPhotoForm,
  },

  props: {
    customerId: { type: String, required: true },
    buttonClasses: { type: String, default: "ui small primary basic button" },
  },

  emits: ["imageAddedToGallery"],

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      showModal: false,
      // Images that need saving to the gallery (they've already been uploaded)
      imagesToSave: [],
    };
  },

  async mounted() {
    // NOTE: We're not using the globalRoot emit that the UploadPhotoForm uses in the cms,
    // instead we're catching individual upload events, emitting to the parent and
    // then saving to the gallery on a timer since we get get a bunch in rapid succession
    // and this reduces contention a lot.
    setInterval(this.checkForSave, 2000);
  },

  methods: {
    onImageUploadedToServer(image: UploadedImage) {
      // Note the it's dirty so we actually perform a save
      this.imagesToSave.push(image.serverId);
      // Immediately emit the event so the parent can update if needed
      this.$emit("imageAddedToGallery", image.serverId);
    },
    async checkForSave() {
      // this.globalLog.info(`UploadPhotosNonAdventure checkForSave fired.`);
      if (this.imagesToSave?.length > 0) {
        this.globalLog.info(`Adding ${this.imagesToSave.length} to the gallery`);
        let imagesToPersist = this.imagesToSave;
        try {
          this.imagesToSave = [];
          const config = { headers: { "Content-Type": "application/json" } };
          var input = {
            photoIds: imagesToPersist,
          };
          const { data } = await axios.post(`${import.meta.env.VITE_API_URL}/photo-gallery/customer/${this.customerId}/photos`, JSON.stringify(input), config);
          this.globalLog.info(`Added ${imagesToPersist.length} to the gallery`);
        } catch (e) {
          this.globalRemoteLogger.error(`PhotoGallery Save FAILED! Customer=${this.customerId}, AssetCount=${this.imagesToSave?.length}, Error=${e.message}`);
          // Put them back and try next time this fires
          this.imagesToSave.push(...imagesToPersist);
        }
      }
    },
    doneUploading() {
      this.showModal = false;
    },
  },
});
</script>
