<template>
  <div class="saved-posts">
    <!-- Back link section -->
    <div class="back-link-section saved-posts__back-link-section" style="margin-top: 4px; margin-bottom: 23px">
      <LinkWithIcon
        class="back-link-section__link"
        :to="
          shareStopSummaryStore.editedPost.id
            ? {
                name: 'ShareStopSummaryV2',
                query: { postId: shareStopSummaryStore.editedPost.id },
                params: { pageId: $route.params.pageId },
              }
            : {
                name: 'ShareStopSummaryV2',
                params: { pageId: $route.params.pageId },
              }
        "
        tag="RouterLink"
      >
        <template #icon><IconEmbedded name="arrow-left_2-5" :size="21" /></template>
        <span>Post Wizard</span>
      </LinkWithIcon>
    </div>
    <!-- / Back link section -->

    <SrpModal v-model:isVisible="isDeletePostModalVisible" size="small">
      <template #header>
        <div class="global-h1" style="margin-bottom: 1px">Remove post</div>
        <div class="global-h3">{{ postToDelete.contextTitle ? `"${postToDelete.contextTitle}"` : "" }}?</div>
      </template>
      <template #content>
        <p>This will remove the post, caption, and selected images.</p>
      </template>
      <template #footer>
        <SrpButton :is-disabled="shareStopSummaryStore.isDeletingPost" @click="isDeletePostModalVisible = false" shape="rectangle" color="gray" style="margin-right: 4px">Cancel</SrpButton>
        <SrpButton
          :isLoading="shareStopSummaryStore.isDeletingPost"
          :isDisabled="shareStopSummaryStore.isDeletingPost"
          @click="deletePost(postToDelete.id)"
          shape="rectangle"
          color="orange"
          is-with-or-separator
        >
          <template v-if="shareStopSummaryStore.isDeletingPost" #icon>
            <IconEmbedded class="gradient-spin-white" name="loader_3" :size="20" />
          </template>
          Delete
        </SrpButton>
      </template>
    </SrpModal>

    <h1 class="global-h1 saved-posts__title">Saved posts</h1>

    <ul class="saved-posts__posts-list">
      <PostSnippet
        class="saved-posts__post"
        v-for="post in allPostsSortedByDate"
        :key="post.id"
        :post="post"
        @delete-post="
          post => {
            isDeletePostModalVisible = true;
            postToDelete = lodashCloneDeep(post); // need to copy the entire post to avoid referenceError when post is deleted and modal is not closed yet
          }
        "
        @edit-post="id => redirectToEditPage(id)"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";
import lodashCloneDeep from "lodash-es/cloneDeep";
import { mapStores } from "pinia";

// Types
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import { SocialMediaPostData } from "@contracts/shareStop";

// Component
import LinkWithIcon from "@components/LinkWithIcon.vue";
import PostSnippet from "./PostSnippet.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";

// Stores
import { useShareStopSummaryStore } from "@stores/shareStopSummary";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "ShareStopSummary",

  components: {
    IconEmbedded,
    LinkWithIcon,
    PostSnippet,
    SrpButton,
    SrpModal,
  },

  data() {
    return {
      // @ts-ignore
      pageId: "",
      title: "Shrpa",
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      orgInContext: null as PartnerNavInfo,

      isDeletePostModalVisible: false,
      postToDelete: null,
    };
  },

  computed: {
    ...mapStores(useShareStopSummaryStore),
    allPostsSortedByDate(): Array<SocialMediaPostData> {
      return lodashCloneDeep(this.shareStopSummaryStore.allPosts).sort((a: SocialMediaPostData, b: SocialMediaPostData): number => {
        const dateA = new Date(a.updatedDate || a.createdDate).getTime();
        const dateB = new Date(b.updatedDate || b.createdDate).getTime();

        return dateA > dateB ? -1 : dateA === dateB ? 0 : 1;
      });
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = String(this.$route.params.pageId);
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;

    await this.shareStopSummaryStore.loadAllPosts(this.pageId);
  },

  methods: {
    lodashCloneDeep,
    async deletePost(postId: string): Promise<void> {
      await this.shareStopSummaryStore.deletePost(this.pageId, postId);
      this.isDeletePostModalVisible = false;

      setTimeout(() => (this.postToDelete = null), 600); // needed to avoid referenceError when post is deleted and modal is not closed yet
    },
    redirectToEditPage(postId: string): void {
      this.shareStopSummaryStore.resetEditedPost();
      this.shareStopSummaryStore.activeStep = 4;

      this.$router.push({
        name: "ShareStopSummaryV2",
        query: { postId: postId },
        params: { pageId: this.$route.params.pageId },
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Back link section ==========================================================
.back-link-section {
  padding-bottom: 12px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__link {
    outline: inherit;
  }
}

// Saved posts ================================================================
.saved-posts {
  width: 945px;
  max-width: 945px;
  margin: 0 auto;

  &__back-link-section {
    outline: inherit;
  }

  &__title {
    margin-bottom: 28px;
  }

  &__posts-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__post {
    width: calc(33.3% - 18px);
    margin: 0 27px 27px 0;
    break-inside: avoid-column;
    page-break-inside: avoid;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .saved-posts {
    width: 822px;
    max-width: 822px;

    &__post {
      width: calc(33.3% - 16px);
      margin: 0 24px 24px 0;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .saved-posts {
    width: 762px;
    max-width: 762px;

    &__post {
      width: calc(33.3% - 15px);
      margin: 0 22px 22px 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .saved-posts {
    width: 502px;
    max-width: 502px;

    &__post {
      width: calc(50% - 9px);
      margin: 0 18px 18px 0;

      &:nth-child(3n) {
        margin-right: 18px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .saved-posts {
    width: 100%;
    max-width: 100%;

    &__post {
      width: calc(50% - 9px);
      margin: 0 17px 17px 0;

      &:nth-child(3n) {
        margin-right: 17px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
