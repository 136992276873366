<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Give Your Creator Visit A Name</div>
      <p class="cms">A short name to describe the purpose of this Creator Visit. This will be shown to the <span @click="gptCreateTitle">creator.</span></p>
      <div
        :class="{
          'form-error-highlight': true,
          'form-error-highlight--bg-visible': errors.includes('Creator Visit Name'),
        }"
        style="width: 100%; max-width: 500px; margin-bottom: 25px"
      >
        <NameInputWithAI ref="collabNameInput" v-model:name="collabInput.name" :customerId="collabInput.customerId" :collabInputId="collabInput.id" />
      </div>
    </div>

    <div class="field">
      <div class="global-h4">Additional Notes for the Creator</div>
      <p class="cms">(Optional) Anything a creator should be aware of before accepting?</p>
      <textarea ref="notesInput" type="text" maxlength="5000" v-model="collabInput.notesForCreator" autocapitalize="sentences" style="height: 140px; margin-bottom: 35px"></textarea>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import transformPhoneToUSMask from "@logic/PhoneMask";

// Types
import { CollabInput } from "@contracts/collab";

// Components
import NameInputWithAI from "@views/PaidCollab/CreateCollab/NameInputWithAI.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "StepName",

  components: { IconEmbedded, NameInputWithAI },

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
    errors: { type: Array<string>, required: true },
  },

  data() {
    return {
      loadingTitles: false,
    };
  },

  watch: {
    "collabInput.destinationPhoneNumber"() {
      if (!this.collabInput.destinationPhoneNumber) {
        return;
      }

      this.collabInput.destinationPhoneNumber = transformPhoneToUSMask(this.collabInput.destinationPhoneNumber);
    },
  },

  mounted() {
    this.$nextTick(() => {
      ((this.$refs.collabNameInput as any).input as HTMLInputElement)?.focus();
    });
  },

  methods: {
    async gptCreateTitle() {
      // Hidden call for the ai-generated titles (until we test it and get a better UI)
      if (this.loadingTitles) return;
      this.loadingTitles = true;
      let result = await axios.get(`${import.meta.env.VITE_API_URL}/collabs/${this.collabInput.customerId}/collabs/${this.collabInput.id}/generate-title`);
      let toShow = result.data.responseText;
      this.loadingTitles = false;
      alert(toShow);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";

// Sent successfully message ==================================================
.sent-successfully-msg {
  padding: 32px 0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcf5d6;

  &__checkmark-icon-wrap {
    width: 97px;
    min-width: 97px;
    height: 97px;
    padding: 0 0 4px 4px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #518345;
    font-size: 54px;
    background: #fff;
  }

  &__checkmark-icon {
  }

  &__titles {
    max-width: 240px;
  }

  &__title {
    margin-bottom: 7px;
  }

  &__subtitle {
  }
}
</style>
