<template>
  <div class="slider-container">
    <VueSlider class="slider-container__slider" v-model="_value" tooltip="always" :data="sliderSteps" data-value="id" data-label="name" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";

const props = withDefaults(
  defineProps<{
    min: number;
    max: number;
    value: number;
  }>(),
  {
    min: 1,
    max: 10,
    value: 5,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: number): void;
}>();

// Components
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

// Slider steps ===============================================================
const sliderSteps = computed<Array<{ id: number; name: string }>>(() => [...new Array(props.max - props.min + 1)].map((_, index) => ({ id: props.min + index, name: `${props.min + index}` })));

// Local value ================================================================
const _value = ref<number>(5);

onMounted(() => {
  _value.value = props.value;
});

watch(_value, () => {
  emit("update:value", _value.value);
});
</script>

<style scoped lang="scss">
// Slider container ===========================================================
.slider-container {
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__slider {
    width: 250px;

    :deep(.vue-slider-dot-handle-focus) {
      outline: 4px #fff solid;
      outline-offset: -1px;
      box-shadow: none;
      box-shadow: 0 1px 3px 5px rgba(0, 0, 0, 0.2);
    }
    :deep(.vue-slider-process) {
      height: 6px;
      background: #15767c;
    }
    :deep(.vue-slider-rail) {
      height: 6px;
      background: #d9d9d9;
    }
    :deep(.vue-slider-dot-tooltip-inner-top) {
      font-weight: bold;
      font-family: sans-serif;
      background: black;
    }
    :deep(.vue-slider-dot-tooltip-inner-top:after) {
      border-top-color: black;
    }
    :deep(.vue-slider-mark-step) {
      background: transparent;
    }
    :deep(.vue-slider-mark-label) {
      top: -38px;
      color: rgba(0, 0, 0, 0.4);
      font-family: sans-serif;
    }
    :deep(.vue-slider-mark) {
      opacity: 0;
    }
    :deep(.vue-slider-mark:first-child) {
      opacity: 1;
    }
    :deep(.vue-slider-mark:last-child) {
      opacity: 1;
    }
  }
}
</style>
