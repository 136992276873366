<template>
  <div class="photo-editor">
    <p class="note">Select the lanscape orientation of the main image that represents your adventure!</p>
    <div class="cropper-wrapper">
      <!-- <div :style="{backgroundImage: `url()`}" class="image-background"></div> -->
      <div class="cropper-loading" v-if="loading">
        <Loader class="big" active style="color: #fff" />
      </div>
      <Cropper
        v-if="selectedImageServerId"
        ref="cropper"
        class="cropper"
        backgroundClass="cropper-bg"
        :src="currentServerIdCdnUri"
        :stencilProps="{
          aspectRatio: 4 / 3,
        }"
        :defaultSize="defaultStencilSize"
        imageRestriction="stencil"
        @ready="ready"
        @error="error"
        ></Cropper>
        <!-- @change="change" -->
    </div>
    <div class="cropper-buttons">
      <button v-if="selectedImageServerId" class="ui small right floated primary button" @click="cropImage">Save</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Cropper } from "vue-advanced-cropper";
import { inject } from "vue";
import Loader from "@components/Loader/Loader.vue";

export default defineComponent({
  name: "ImageCropper",

  components: {
    Loader,
    Cropper,
  },

  props: {
    selectedImageServerId: {
      type: String,
      required: true,
    },
  },


  emits: {
    onCrop: (_image: File) => true,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      activeImage: 0 as number,
      loading: true as boolean,
      mainImage: null as string,
      // For some reason the CDN is causing CORS issues with the cropping library
      // @ts-ignore;
      nonCdnUncompressedContentBase: `${globalThis.Bootstrap.Config.contentBlobBaseUri}/cms/images/uncompressed/`,
      // Note: Using uncompressed ^ above to reduce the compression loss if cropping down from a vertical photo
    };
  },
  computed: {
    currentServerIdCdnUri(): string {
      return `${this.nonCdnUncompressedContentBase}${this.selectedImageServerId}`;
    },
  },

  mounted() {
    this.loading = true;
  },

  methods: {
    error(e) {
      this.loading = true;
      this.globalLog.error("ImageCropper Failure! " + e);
    },
    ready() {
      this.loading = false;
    },
    cropImage() {
      this.loading = true;
      // @ts-ignore
      const croppedImage = this.$refs.cropper.getResult().canvas;
      croppedImage.toBlob(
        blob => {
          const imageFile = new File([blob], "cropped-tile-image.jpeg", { lastModified: new Date().getTime(), type: blob.type });
          this.$emit("onCrop", imageFile);
        },
        "image/jpeg",
        1
      );
    },
    defaultStencilSize({ imageSize }) {
      const size = imageSize.width > imageSize.height ? imageSize.width : imageSize.height;
      // Find the longest dimention and use that.
      // This select as much as possible to crop while obeying a given aspect ratio.
      // So for portait full width and 4:3 landscape upload it select the entire photo.
      // Inspired by https://github.com/Norserium/vue-advanced-cropper/issues/79
      return {
        width: size,
        height: size,
      };
    },
  },
});
</script>

<style lang="scss">
// @import 'vue-advanced-cropper/dist/style.css';
.cropper {
  border: solid 1px #eee;
  width: 100%;
  height: 400px;
  max-height: 40vh;
  // background: #DDD;
  border-radius: 10px;

  .vue-simple-handler {
    background: #058587;
  }
  .vue-simple-line {
    border-color: #058587;
  }
  .vue-advanced-cropper__foreground {
    opacity: 0.8;
    cursor: grab;
  }
  .cropper-bg {
    background: transparent;
  }
  &-background {
    background: none;
  }
  &-wrapper {
    overflow: hidden;
    position: relative;

    .image-background {
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      left: -10px;
      top: -10px;
      background-size: cover;
      background-position: 50%;
      filter: blur(5px);
    }
  }
  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000a3;
    z-index: 1;
  }
  &-buttons {
    // z-index: 2;
    // position: absolute;
    // bottom: 0;
    // right: 0;
    padding: 15px 0;
  }

  @media only screen and (max-width: 767px) {
    height: 210px;
  }
}
</style>
