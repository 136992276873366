<template>
  <div style="margin: 0 auto; display: flex; flex-direction: column; align-items: center">
    <h1 v-if="props.isWithH1" class="global-h1" style="margin-bottom: 30px">Latest on Shrpa</h1>

    <Loader v-if="isFetching && activityFeed?.length === 0" />

    <ul
      v-else
      :class="{
        'activity-feed-list': true,
        'activity-feed-list--disabled': isFetching,
      }"
    >
      <template v-for="(feedItem, index) in activityFeed" :key="feedItem.id">
        <TimeFrameDivider v-if="defineIsThisWeekVisible(feedItem, index)" class="activity-feed-list__date-grouping-divider" :eventsStats="thisWeekCounts"> This Week </TimeFrameDivider>

        <TimeFrameDivider v-if="defineIsLastWeekVisible(feedItem, index)" class="activity-feed-list__date-grouping-divider" :eventsStats="lastWeekCounts"> Last Week </TimeFrameDivider>

        <CollabCompletedSection v-if="feedItem.type === 'CollabCompleted'" class="activity-feed-list__snippet" :feedItem="feedItem" />

        <CollabPostedSection
          v-if="feedItem.type === 'CollabPosted'"
          :hideApplyButton="viewingContext === 'customer'"
          class="activity-feed-list__snippet"
          :feedItem="feedItem"
          @clickApply="emit('clickApply')"
        />

        <NewDestinationSection v-if="feedItem.type === 'NewDestination'" class="activity-feed-list__snippet" :feedItem="feedItem" />

        <NewPaidCreatorSection v-if="feedItem.type === 'NewPaidCreator'" class="activity-feed-list__snippet" :feedItem="feedItem" />
      </template>
    </ul>

    <div v-if="userProfileStore.isSuperOrSalesUser && allowShowMore" style="width: 100%; height: 30px; display: flex; justify-content: center; align-items: center">
      <LinkWithIcon v-if="!isFetching" @click="fetchActivityFeed(100)" isDottedUnderline>
        <template #icon><IconEmbedded name="caret-bottom_2" :size="25" /></template>
        <span>Show more (super only)</span>
      </LinkWithIcon>

      <Loader v-else style="margin-top: 0" />
    </div>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "ActivityFeed" };</script>

<script setup lang="ts">
import axios from "axios";
import DateUtils from "@logic/DateUtils";
import { onMounted, ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import Loader from "@components/Loader/Loader.vue";
import TimeFrameDivider from "@components/ActivityFeed/TimeFrameDivider.vue";
// Event sections
import CollabCompletedSection from "./CollabCompletedSection.vue";
import CollabPostedSection from "./CollabPostedSection.vue";
import NewDestinationSection from "./NewDestinationSection.vue";
import NewPaidCreatorSection from "./NewPaidCreatorSection.vue";

// Types
import { CollabCompletedFeedItem, CollabPostedFeedItem, NewDestinationFeedItem, NewPaidCreatorFeedItem } from "@components/ActivityFeed/types";
import { EventStats } from "@components/ActivityFeed/TimeFrameDivider.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

const props = withDefaults(
  defineProps<{
    isWithH1?: boolean;
    allowShowMore?: boolean;
    viewingContext?: "creator" | "customer";
  }>(),
  {
    isWithH1: true,
    allowShowMore: true,
    viewingContext: "creator",
  }
);

const emit = defineEmits<{
  (e: "clickApply"): void;
}>();

// Fetch activity feed ========================================================
const activityFeed = ref<Array<CollabCompletedFeedItem | CollabPostedFeedItem | NewDestinationFeedItem | NewPaidCreatorFeedItem>>([]);
const isFetching = ref(false);

onMounted(async () => {
  await fetchActivityFeed();
});

const thisWeekCounts = ref<EventStats>(null);
const lastWeekCounts = ref<EventStats>(null);

async function fetchActivityFeed(howManyToLoad = 0) {
  let uri = `${import.meta.env.VITE_API_URL}/activity-feed/v2`;

  const urlSearchParams = new URLSearchParams();
  if (howManyToLoad) urlSearchParams.append("resultCount", String(howManyToLoad));

  isFetching.value = true;
  const { data } = await axios.get(uri + "?" + urlSearchParams.toString());
  isFetching.value = false;

  activityFeed.value = data.items;
  thisWeekCounts.value = data.thisWeekCounts;
  lastWeekCounts.value = data.lastWeekCounts;
}

// Define dividers visibility =================================================
function defineIsThisWeekVisible(feedItem, index): boolean {
  if (!feedItem) {
    return false;
  }
  return index === 0 && DateUtils.checkIsThisWeek(feedItem.eventDate);
}
function defineIsLastWeekVisible(feedItem, index): boolean {
  if (!feedItem) {
    return false;
  }
  return index !== 0 && DateUtils.checkIsLastWeek(feedItem.eventDate) && !DateUtils.checkIsLastWeek(activityFeed.value[index - 1]?.eventDate);
}

function defineIsLastMonthVisible(feedItem, index): boolean {
  if (!feedItem) {
    return false;
  }
  return index !== 0 && DateUtils.checkIsLastMonth(feedItem.eventDate) && !DateUtils.checkIsLastMonth(activityFeed.value[index - 1]?.eventDate) && !DateUtils.checkIsLastWeek(feedItem.eventDate);
}

function defineIsEarlierVisible(feedItem, index): boolean {
  if (!feedItem) {
    return false;
  }
  return index !== 0 && DateUtils.checkIsLastMonth(activityFeed.value[index - 1]?.eventDate) && !DateUtils.checkIsLastMonth(feedItem.eventDate);
}

function defineIsAnyDividerVisible(feedItem, index): boolean {
  return defineIsLastWeekVisible(feedItem, index) || defineIsLastMonthVisible(feedItem, index) || defineIsEarlierVisible(feedItem, index);
}
</script>

<style scoped lang="scss">
@import "./commonStyle.scss";

// Activity feed list =========================================================
.activity-feed-list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__snippet {
    width: 100%;
  }

  &__snippet + &__snippet {
    margin-top: -4px;
    border-radius: 0 0 6px 6px;
  }

  &__date-grouping-divider {
    margin: 13px 0 22px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__snippet + &__date-grouping-divider {
    margin-top: 33px;
  }
}
</style>
