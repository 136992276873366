<template>
  <div class="name-input-with-ai">
    <input class="name-input-with-ai__input" ref="input" type="text" v-model="_name" />

    <!-- AI link snippet -->
    <div
      :class="{
        'ai-link-snippet': true,
        'ai-link-snippet--disabled': isDropdownVisible,
        'name-input-with-ai__ai-link-snippet': true,
      }"
      @click="toggleDropdownVisibility(true)"
    >
      <!--<div class="ai-link-snippet__beta-sign">beta</div>-->

      <LinkWithIcon
        @click="generateAiTitlesClick"
        class="ai-link-snippet__link"
        color="orange"
        :isDisabled="isDropdownVisible"
        isDottedUnderline
        iconInset="1px auto auto 2px"
        style="margin-left: -3px"
      >
        <template #icon><IconEmbedded name="ai_2" :size="19" /></template>
        <span>Generate with AI</span>
      </LinkWithIcon>
    </div>
    <!-- / AI link snippet -->

    <!-- AI generated dropdown -->
    <div v-if="isDropdownVisible" class="name-input-with-ai__suggestions-dropdown ai-generated-dropdown" v-click-outside="() => toggleDropdownVisibility(false)">
      <div
        :class="{
          'ai-generated-dropdown__in-wrap1': true,
          'ai-generated-dropdown__in-wrap1--invisible': !isDropdownContentVisible,
        }"
      >
        <div class="ai-generated-dropdown__in-wrap2">
          <div class="ai-generated-dropdown__list-n-button">
            <ul class="ai-generated-dropdown__suggestions-list">
              <li
                :class="{
                  'ai-generated-dropdown__suggestion': true,
                  'ai-generated-dropdown__suggestion--disabled': isLoading,
                }"
                v-for="suggestion in isLoading ? suggestionsListMock : suggestionsList"
                :key="suggestion"
                @click="aiTitleClick(suggestion)"
              >
                <span
                  :class="{
                    'ai-generated-dropdown__suggestion-text': true,
                    'ai-generated-dropdown__suggestion-text--skeleton': isLoading,
                  }"
                >
                  {{ suggestion }}
                </span>
              </li>
            </ul>

            <SrpButton class="ai-generated-dropdown__regenerate-btn" color="gray" fill="outlined" size="tiny" :isDisabled="isLoading" @click="getSuggestions">
              <template #icon><IconEmbedded name="reload_2-5" color="rgba(0, 0, 0, 0.3)" :size="18" /></template>
              Regenerate
            </SrpButton>
          </div>

          <!-- Note section -->
          <div class="note-section ai-generated-dropdown__note-section">
            <IconEmbedded class="note-section__icon" name="info-simple_4" />
            <div class="note-section__text">AI generation may lead to odd results sometimes</div>
          </div>
          <!-- / Note section -->
        </div>
      </div>
    </div>
    <!-- / AI generated dropdown -->
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, inject } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import { onMounted } from "vue";
import axios from "axios";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export interface Props {
  name: string;
  customerId: string;
  collabInputId: string;
}
const props = withDefaults(defineProps<Props>(), {
  name: "",
  customerId: "",
  collabInputId: "",
});

const emit = defineEmits<{
  (e: "update:name", event: string): void;
}>();

// Toggle dropdown ============================================================
const isDropdownVisible = ref(false);
const isDropdownContentVisible = ref(false);

function toggleDropdownVisibility(isVisible: boolean): void {
  if (isVisible) {
    isDropdownVisible.value = true;
    setTimeout(() => (isDropdownContentVisible.value = true), 0);
  } else {
    isDropdownContentVisible.value = false;
    setTimeout(() => (isDropdownVisible.value = false), 100);
  }
}

// Name input two way binding =================================================
const _name = computed({ get: () => props.name, set: newVal => emit("update:name", newVal) });

// Expose input dom ref =======================================================
const input = ref(null);
defineExpose({ input });

// Get suggestions list =======================================================
const isLoading = ref(false);

const suggestionsListMock = [
  "Lorem ipsum dolor sit amet, consectetur",
  "Dolore eu fugiat aliquip",
  "Ullamco laboris nisi ut aliquip ex ea commodo",
  "Dolore eu fugiat nulla pariatur",
  "Sunt in culpa qui officia deserunt mollit",
];
const suggestionsList = ref([]);

async function generateAiTitlesClick(): Promise<void> {
  if (!suggestionsList.value.length) {
    await getSuggestions();
  }
}

async function getSuggestions(): Promise<void> {
  isLoading.value = true;

  const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.customerId}/collabs/${props.collabInputId}/generate-title`;
  const { data } = await axios.get(uri);
  suggestionsList.value = data.titles;

  isLoading.value = false;
  globalRemoteLogger.info(`ai-collab-title-generate for ${props.customerId} ${props.collabInputId}`);
}

const globalRemoteLogger = inject("globalRemoteLogger") as any;

function aiTitleClick(suggestion: string) {
  emit("update:name", suggestion);
  toggleDropdownVisibility(false);
  globalRemoteLogger.info(`ai-collab-title-chosen for ${props.customerId} ${props.collabInputId}: ${suggestion}`);
}
</script>

<style scoped lang="scss">
@import "@/scss/mixins/animations/skeleton-loading.scss";

// Note section ===============================================================
.note-section {
  display: flex;
  align-items: center;

  &__icon {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.13);
  }

  &__text {
  }
}

// AI generated dropdown ======================================================
.ai-generated-dropdown {
  border: 1px #c3cdd1 solid;
  border-radius: 0 0 5px 5px;
  color: #5b5b5b;
  font: 14px/18px sans-serif;
  background: #f3f3f3;
  box-shadow: 0 4px 20px -5px rgba(0, 0, 0, 0.29);

  &__in-wrap1 {
    display: grid;
    grid-template-rows: 1fr;
    transform: translateZ(0);
    transition: grid-template-rows 0.07s ease-in-out;

    &--invisible {
      grid-template-rows: 0fr;
    }
  }

  &__in-wrap2 {
    padding: 7px 18px 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
  }

  &__list-n-button {
    padding-bottom: 18px;
    margin-bottom: 11px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__suggestions-list {
    width: calc(100% + 18px * 2);
    padding: 0;
    margin: 0 0 12px -18px;
    list-style: none;
  }

  &__suggestion {
    padding: 5px 18px 3px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__suggestion-text {
    display: inline-block;
    overflow: hidden;

    &--skeleton {
      @include skeletonLoading;
      color: transparent;
    }
  }

  &__regenerate-btn {
  }

  &__note-section {
  }
}

// AI link snippet ============================================================
.ai-link-snippet {
  display: inline-block;
  position: relative;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__link {
  }

  &__beta-sign {
    position: absolute;
    inset: -5px 0 auto auto;
    color: rgba(0, 0, 0, 0.4);
    font: italic 10px/10px sans-serif;
  }
}

// Name input with AI =========================================================
.name-input-with-ai {
  position: relative;

  &__input {
    width: 100%;
    max-width: 500px;
    margin-bottom: 7px !important;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
  }

  &__ai-link-snippet {
  }

  &__suggestions-dropdown {
    width: 100%;
    position: absolute;
    inset: 37px auto auto 0;
    z-index: 1;
  }
}
</style>
