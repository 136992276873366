<template>
  <GMapAutocomplete
    :value="paidCreatorFields.locationName"
    placeholder="Home town"
    @place_changed="googlePlaceChosen"
    @input="e => placeTextChanged(e.target.value)"
    :options="{
      fields: ['name', 'place_id', 'address_components', 'formatted_address', 'geometry', 'website'],
    }"
    :types="['(cities)']"
    style="max-width: 300px"
  />
</template>

<script setup lang="ts">
import { PaidCreatorFields } from "@contracts/userProfile";
import AddressHelper from "@helpers/AddressHelper";
import { useLogger } from "@helpers/Logger";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

const props = defineProps<{
  paidCreatorFields: PaidCreatorFields;
}>();
const emit = defineEmits<{
  (e: "update:paidCreatorFields", value: PaidCreatorFields): void;
}>();

const logger = useLogger();
const remoteLogger = getGlobalRemoteLogger();

/**
 * NOTE! This doesn't catch text changes, just when they pick a result from the auto-complete
 */
function googlePlaceChosen(place: globalThis.google.maps.places.PlaceResult) {
  const updatedPaidCreatorFields = { ...props.paidCreatorFields };
  try {
    if (place?.geometry?.location) {
      updatedPaidCreatorFields.latitude = place.geometry.location.lat();
      updatedPaidCreatorFields.longitude = place.geometry.location.lng();
      // Original locationName text field that we show most places
      updatedPaidCreatorFields.locationName = AddressHelper.removeCountryAndZipCode(place.formatted_address ?? place.name);
      // Note: Similar logic in GooglePlaceConverter
      updatedPaidCreatorFields.country = place.address_components.find(c => c.types.includes("country"))?.short_name;
      updatedPaidCreatorFields.state = place.address_components.find(c => c.types.includes("administrative_area_level_1"))?.short_name;
      updatedPaidCreatorFields.city = place.address_components.find(c => c.types.includes("locality"))?.short_name;
      // If city is null fallback to AdminAreaL3 (typically a Township), then L2 (typically a county)
      // note: May not need this logic here since we restrict the types to cities
      if (!updatedPaidCreatorFields.city) {
        updatedPaidCreatorFields.city = place.address_components.find(c => c.types.includes("administrative_area_level_3"))?.short_name;
      }
      if (!updatedPaidCreatorFields.city) {
        updatedPaidCreatorFields.city = place.address_components.find(c => c.types.includes("administrative_area_level_2"))?.short_name;
      }
      updatedPaidCreatorFields.country = place.address_components.find(c => c.types.includes("country")).short_name;
      logger.info(
        `googlePlaceChosen: ${place?.name}, City=${updatedPaidCreatorFields.city}, State=${updatedPaidCreatorFields.state}, Country=${updatedPaidCreatorFields.country} Id=${place?.place_id}, Lat=${updatedPaidCreatorFields.latitude}, Lng=${updatedPaidCreatorFields.longitude}`
      );

      emit("update:paidCreatorFields", updatedPaidCreatorFields);
    }
  } catch (ex) {
    remoteLogger.error(`Place Autocomplete failure: Input=${props.paidCreatorFields.locationName} Name=${place?.name} PlaceId=${place?.place_id}  ${ex.message}`);
  }
}

/**
 * This method handles text changes to the stop title
 */
function placeTextChanged(newTitle: string) {
  // @ts-ignore
  logger.info("PlaceTextChanged: " + newTitle);
  const updatedPaidCreatorFields = { ...props.paidCreatorFields };
  updatedPaidCreatorFields.locationName = AddressHelper.removeCountryAndZipCode(newTitle);

  // If they cleared their location, null out the lat/lng
  if (!newTitle || newTitle.trim().length === 0) {
    updatedPaidCreatorFields.latitude = null;
    updatedPaidCreatorFields.longitude = null;
    updatedPaidCreatorFields.city = null;
    updatedPaidCreatorFields.state = null;
    updatedPaidCreatorFields.country = null;
    logger.info(`Clearing location fields:`);
  }

  emit("update:paidCreatorFields", updatedPaidCreatorFields);
}
</script>
