<template>
  <ul class="step-wizard" ref="stepWizardRef" :style="{ height: listContainerHeight }">
    <!-- Step container -->
    <li
      :class="{
        'step-container': true,
        'step-container--overflow-hidden': isTransitionInProgress,
        'step-container--with-top-curtain': props.isWithTopCurtain && index !== 0,
        'step-container--with-bottom-curtain': index !== slotsListSorted.length - 1 && _activeSlide === index,
        'step-wizard__step-container': true,
        'step-wizard__step-container--absolute-positioned': isTransitionInProgress,
      }"
      v-for="(slotName, index) in slotsListSorted"
      :key="slotName"
      ref="slideRefs"
      :data-slotnumber="+slotName"
    >
      <TransitionSlide :offset="[0, calcSlideYOffset(index, slideDirection)]" :duration="transitionDuration" easing="ease-in-out" :delay="50">
        <div
          v-if="_activeSlide === index"
          :class="{
            'step-container__in-wrap': true,
            'step-container__in-wrap--absolute-positioned': false,
          }"
        >
          <LinkWithIcon v-if="index !== 0" class="step-container__previous-step-link" @click="goToTheSlide(index - 1)">
            <template #icon><IconEmbedded name="arrow-top_2-5" :size="25" /></template>
            <span>{{ getTitle(slotsListSorted[index - 1]) }}</span>
          </LinkWithIcon>

          <div
            :class="{
              'step-container__slides-guide-line': true,
              'step-container__slides-guide-line--not-connected-to-top': index === 0,
              'step-container__slides-guide-line--only-from-top': !slotsListSorted[index + 1] && index + 1 !== 1,
              'step-container__slides-guide-line--not-connected-to-bottom': index === slotsListSorted.length - 2,
            }"
          ></div>

          <!-- Header -->
          <div class="header step-container__header">
            <div
              :class="{
                'header__slide-number': true,
                'header__slide-number--stick-to-left-edge': isStickNumbersToLeftEdge,
              }"
            >
              {{ index + 1 }}
            </div>
            <div class="header__title-n-est-time">
              <div
                :class="{
                  'global-h2': true,
                  header__title: true,
                  'header__title--top-padding': !slotName.split('___')[2],
                }"
              >
                {{ getTitle(slotName) }}
              </div>
              <div v-if="getDescription(slotName)" class="header__description">{{ getDescription(slotName) }}</div>
            </div>
          </div>
          <!-- / Header -->

          <!-- Slide content -->
          <div class="slide-content step-container__content">
            <slot :name="slotName" :goToTheSlide="goToTheSlide" :goToPrevSlide="goToPrevSlide" :goToNextSlide="goToNextSlide" :updateSlotsList="updateSlotsList" />
          </div>
          <!-- / Slide content -->

          <!-- Header -->
          <div v-if="slotsListSorted[index + 1]" class="header step-container__next-step-header">
            <div
              :class="{
                'header__slide-number': true,
                'header__slide-number--stick-to-left-edge': isStickNumbersToLeftEdge,
                'header__slide-number--dimmed': true,
              }"
            >
              {{ index + 2 }}
            </div>
            <div class="header__title-n-est-time">
              <div class="global-h2 header__title header__title--dimmed">
                {{ getTitle(slotsListSorted[index + 1]) }}
              </div>
            </div>
          </div>
          <!-- / Header -->
        </div>
      </TransitionSlide>
    </li>
    <!-- / Step container -->
  </ul>
</template>

<script setup lang="ts">
import { useSlots, ref, watch, onMounted } from "vue";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import { TransitionSlide } from "@morev/vue-transitions";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    isStickNumbersToLeftEdge?: boolean;
    activeSlide?: number;
    isWithScrollReset?: boolean;
    isWithTopCurtain?: boolean;
    titles: Array<{ title: string; subtitle?: string }>;
  }>(),
  {
    isStickNumbersToLeftEdge: false,
    activeSlide: 0,
    isWithScrollReset: false,
    isWithTopCurtain: false,
    titles: () => [],
  }
);

const emit = defineEmits<{
  (e: "slideChange"): void;
  (e: "update:activeSlide", value: number): void;
}>();

const slots = useSlots();

// Sort slots list ============================================================
/* when update on the one slot affects one of the next slot's dynamic name the slot with updated name moves to the end of the slots array which is not good. Sorting should fix that issue.*/
const slotsListSorted = ref(Object.keys(slots).sort((a, b) => +a - +b));
// this function must be fired externally on dynamic slot name update because vue provides no tools to detect slots array change
function updateSlotsList() {
  setTimeout(() => (slotsListSorted.value = Object.keys(slots).sort((a, b) => +a - +b)), 0);
}

// Switch slides ==============================================================
const _activeSlide = ref(props.activeSlide ? props.activeSlide - 1 : 0);
const stepWizardRef = ref<HTMLUListElement | null>(null);
const isTransitionInProgress = ref(false);
const transitionDuration = 180;

watch(
  () => props.activeSlide,
  () => {
    let newActiveSlide;
    if (props.activeSlide <= 0) newActiveSlide = 0;
    else if (props.activeSlide >= 1 && props.activeSlide <= slotsListSorted.value.length) newActiveSlide = props.activeSlide - 1;
    else newActiveSlide = slotsListSorted.value.length - 1;

    goToTheSlide(newActiveSlide);
  }
);

function goToTheSlide(slideIndex) {
  const overallSlidesNumber = slotsListSorted.value.length;

  slideIndex = slideIndex > overallSlidesNumber - 1 ? overallSlidesNumber - 1 : slideIndex;
  slideIndex = slideIndex < 0 ? 0 : slideIndex;

  handleSmoothHeightTransition(slideIndex);

  _activeSlide.value = slideIndex;

  emit("update:activeSlide", _activeSlide.value + 1);

  setTimeout(() => (stepWizardRef.value.scrollTop = 0), 0);

  // set position absolute to all inactive containers
  // to fix the transition b-u-g where inactive slide disappears
  isTransitionInProgress.value = true;
  setTimeout(() => (isTransitionInProgress.value = false), transitionDuration + 50);

  emit("slideChange");
}

function goToPrevSlide() {
  goToTheSlide(_activeSlide.value - 1);
}
function goToNextSlide() {
  goToTheSlide(_activeSlide.value + 1);
}

// Handle smooth height transition ============================================
const listContainerHeight = ref("auto");
const slideRefs = ref([]);

function handleSmoothHeightTransition(slideIndex) {
  listContainerHeight.value = Math.round(stepWizardRef.value.getBoundingClientRect().height) + "px";

  setTimeout(() => {
    /* when update on the one slot affects one of the next slot's dynamic name the slot with updated name moves to the end of the slots array which is not good. Sorting should fix that issue.*/
    const sortedRefsList = slideRefs.value.sort((a, b) => +a.dataset.slotnumber - +b.dataset.slotnumber);
    const newHeight = Math.round(sortedRefsList[slideIndex].getBoundingClientRect().height) + "px";
    listContainerHeight.value = newHeight;
  }, 20);

  setTimeout(() => (listContainerHeight.value = "auto"), transitionDuration + 50);
}

// Define the slide change direction ==========================================
// to set the right Y offset transition
const slideDirection = ref<"top" | "bottom">("top");
watch(
  () => _activeSlide.value,
  (newValue, oldValue) => (slideDirection.value = newValue > oldValue ? "bottom" : "top")
);

// Calc slide Y offset ========================================================
function calcSlideYOffset(slideIndex, direction) {
  let offset = 0;
  const offsetFactor = 700;

  if (direction === "top") {
    if (slideIndex === _activeSlide.value) offset = offsetFactor * -1;
    if (slideIndex > _activeSlide.value) offset = offsetFactor;
    if (slideIndex < _activeSlide.value) offset = offsetFactor;
  } else {
    if (slideIndex === _activeSlide.value) offset = offsetFactor;
    if (slideIndex > _activeSlide.value) offset = offsetFactor;
    if (slideIndex < _activeSlide.value) offset = offsetFactor * -1;
  }

  return offset;
}

// Extract title/description from slotName ====================================
function getTitle(slotName: string): string {
  return props.titles[+slotName]?.title;
}
function getDescription(slotName: string): string {
  return props.titles[+slotName]?.subtitle;
}

// Reset body scroll position on slide change =================================
watch(
  () => _activeSlide.value,
  () => {
    if (props.isWithScrollReset) {
      setTimeout(() => window.scrollTo({ left: 0, top: 0, behavior: "smooth" }), 100);
    }
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  display: flex;
  align-items: flex-start;

  &__slide-number {
    width: 28px;
    min-width: 28px;
    height: 28px;
    margin: 3px 16px 0 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font:
      700 15px/15px "Quicksand",
      sans-serif;
    background: #3d8528;

    &--dimmed {
      background: #d9d9d9;
    }

    &--stick-to-left-edge {
    }
  }

  &__title-n-est-time {
  }

  &__title {
    margin-bottom: 11px;
    line-height: 24px;
    text-wrap: balance;

    &:last-child {
      margin-bottom: 0;
    }

    &--dimmed {
      color: #b4b3b3;
      font-weight: 500;
      line-height: 35px;
    }

    &--top-padding {
      padding-top: 4px;
    }
  }

  &__description {
    color: #5b5b5b;
    font:
      normal 14px/19px "Helvetica Neue",
      sans-serif;
    text-wrap: balance;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    &__title {
      line-height: 24px;

      &--dimmed {
        padding-top: 4px;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    &__slide-number {
      min-width: 28px;
      height: 28px;
      margin: 3px 10px 0 0;
      border-radius: 100px;

      &--stick-to-left-edge {
        min-width: 32px;
        margin: 3px 10px 0 0;
        border-radius: 0 100px 100px 0;
      }
    }

    &__title {
      margin-bottom: 1px;

      &--dimmed {
        padding-top: 4px;
        line-height: 24px;
      }
    }
  }
}

// Slide container ============================================================
.step-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 120px;
    position: absolute;
    inset: auto auto 0 0;
    z-index: 4;
    opacity: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
    transition: opacity 0.18s ease-in-out;
    pointer-events: none;
  }

  &::before {
    width: 30px;
    height: 50px;
    inset: 0 auto auto 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0) 100%);
  }

  &--with-top-curtain {
    &::before {
      opacity: 1;
    }
  }

  &--with-bottom-curtain {
    &::after {
      opacity: 1;
    }
  }

  &--overflow-hidden {
    overflow: hidden;
  }

  &__in-wrap {
    width: 100%;
    padding: 28px 0 0 0;
    box-sizing: border-box;
    position: relative;
    transform: translateZ(0);

    &--absolute-positioned {
      position: absolute;
    }
  }

  &__slides-guide-line {
    width: 2px;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 13px;
    z-index: -1;
    background: #d5d5d5;

    &--not-connected-to-top {
      height: calc(100% - 45px) !important;
      top: 45px !important;
    }

    &--not-connected-to-bottom {
      height: calc(100% - 105px) !important;
    }

    &--only-from-top {
      height: 75px;
      top: 0;
    }
  }

  &__previous-step-link {
    max-height: 36px;
    margin: -9px 0 18px 42px;
    opacity: 0.5;
  }

  &__header {
    margin-bottom: 28px;
  }

  &__content {
    width: calc(100% - 47px);
    margin: 0 0 120px 47px;
    box-sizing: border-box;

    &--smaller-bottom-padding {
      padding-bottom: 33px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__next-step-header {
    margin-bottom: 100px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .step-container {
    &__in-wrap {
      padding: 18px 0 0 0;
    }

    &--with-bottom-curtain {
      &::after {
        height: 70px;
      }
    }

    &__slides-guide-line {
      inset: 0 auto auto 13px;

      &--not-connected-to-top {
      }

      &--not-connected-to-bottom {
        height: calc(100% - 85px) !important;
      }

      &--only-from-top {
        height: 67px;
        top: 0;
      }
    }

    &__header {
      margin-bottom: 18px;
    }

    &__previous-step-link {
      margin-left: 35px;
    }

    &__content {
      margin: 0 0 45px 40px;
    }

    &__next-step-header {
      margin-bottom: 60px;
    }
  }
}

// Step wizard ================================================================
.step-wizard {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
  transition: height 0.18s ease-in-out;

  &__step-container {
    width: 100%;

    &--absolute-positioned {
      position: absolute;
      inset: 0 auto auto 0;
    }
  }
}
</style>
