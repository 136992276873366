<template>
  <div>
    <div class="ui form">
      <div style="margin-bottom: 15px">
        <div>
          <h1 style="margin-bottom: 10px; display: inline">Your Uploaded Photos</h1>
          <!--<button class="ui small basic button right floated" @click="back()">back</button>-->
        </div>
        <div>
          <!--Want to use your photos that aren't included in an adventure?  Upload them below!<br/>-->
          You can see photos included in your adventures
          <router-link v-if="customerId" class="item" lang="en" hreflang="en" style="text-decoration: underline" :to="{ name: 'CommunityPhotosV2', params: { pageId: customerId } }">here</router-link>
        </div>
      </div>
    </div>

    <Loader v-if="isLoading" />
    <div v-else>
      <!--Note: This markup is very similar to the PhotoWallAdmin page-->
      <div class="column">
        <div class="ui segment">
          <div class="ui grid">
            <div class="sixteen wide column">
              <div class="main-wrapper">
                <div class="adventure-name-wrapper">
                  <div style="margin-bottom: 1.5rem">
                    <h3 style="display: inline; margin-right: 3px">Your Uploaded Photos</h3>
                    <span> - {{ nonAdventurePhotos.length }} photos</span>
                  </div>
                  <!--<i :class="`angle big primary icon ${collapseNonAdventureGallery ? 'down' : 'up'}`" />-->
                </div>
              </div>
            </div>

            <div class="sixteen wide column">
              <div style="margin: -30px 0 8px 5px">
                <div v-if="!nonAdventurePhotos || nonAdventurePhotos.length === 0" style="margin-bottom: 10px">Want to add your photos that aren't included in an adventure?</div>
                <button v-if="nonAdventurePhotos && nonAdventurePhotos.length > 0" :disabled="imageIdsSelected.length === 0" @click="removeSelectedImages" class="ui small basic right floated button">
                  Delete Selected Photos
                </button>
                <UploadPhotosNonAdventure :customerId="customerId" @imageAddedToGallery="addImageToNonAdventureGallery" />
              </div>

              <div
                :class="{
                  'columns-layout': true,
                  'columns-layout--7-col': !['mobile', 'tablet', 'tablet-large'].includes(screenSize),
                  'columns-layout--5-col': screenSize === 'tablet-large',
                  'columns-layout--4-col': screenSize === 'tablet',
                  'columns-layout--2-col': screenSize === 'mobile',
                }"
              >
                <div
                  v-for="(image, index) in nonAdventurePhotos"
                  :key="index"
                  @click="selectImage(image)"
                  :class="{
                    'photo-div': true,
                    'selected-image': imageIdsSelected.indexOf(image) !== -1,
                    'columns-layout__item': true,
                  }"
                >
                  <div class="ui compact segment select-photo">
                    <div class="ui fitted checkbox">
                      <input type="checkbox" name="select-individual-photo" :checked="imageIdsSelected.indexOf(image) !== -1" />
                      <label></label>
                    </div>
                  </div>
                  <template v-if="isVideo(image)">
                    <VideoRender :videoId="image" :customClasses="'photogallery-admin-asset'" :showControls="false" :showProgressBarOnUpload="true" />
                  </template>
                  <template v-else>
                    <img :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`" class="photogallery-admin-asset" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Remove Photo Modal-->
      <SrpModal v-model:isVisible="showRemovePhotoModal">
        <template #header>
          <h2 class="global-h2">Are you sure you remove these {{ imageIdsSelected.length }} photos?</h2>
        </template>
        <template #footer>
          <h3 style="display: inline">{{ saveStatus }}</h3>
          <div class="ui basic black button" @click="showRemovePhotoModal = false">Cancel</div>
          <button class="ui red button" :disabled="saveStatus != null" @click="removeImageConfirmed()">Yep, remove them.</button>
        </template>
      </SrpModal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import FileUtils from "@logic/FileUtils";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Components
import Loader from "@components/Loader/Loader.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import UploadPhotosNonAdventure from "../CMS/UploadPhotosNonAdventure.vue";
import VideoRender from "@components/VideoRender.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "PhotoGalleryAdmin",

  components: {
    SrpModal,
    Loader,
    UploadPhotosNonAdventure,
    VideoRender,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      screenSize: inject("screenSize") as ScreenSize,

      title: "Your Photo Uploads",
      customerId: null as string | null,
      isLoading: true,
      isLoadingMore: false,
      saveStatus: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      nonAdventurePhotos: [] as string[],
      imageIdsSelected: [] as string[],
      showRemovePhotoModal: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    await this.getAllData();
  },

  methods: {
    async getAllData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/photo-gallery/customer/${this.customerId}`);
      this.nonAdventurePhotos = photoResult.data.photoIds;
      this.isLoading = false;
    },
    addImageToNonAdventureGallery(imageId: string) {
      // Add it to the start
      this.nonAdventurePhotos.unshift(imageId);
    },
    selectImage(image: string) {
      const index = this.imageIdsSelected.indexOf(image);
      if (index > -1) {
        this.imageIdsSelected.splice(index, 1);
      } else {
        this.imageIdsSelected.push(image);
      }
    },
    removeSelectedImages() {
      // Ask if they're sure
      this.showRemovePhotoModal = true;
    },
    async removeImageConfirmed() {
      this.saveStatus = "Removing...";
      // Save the changes
      var input = {
        photoIds: this.imageIdsSelected,
      };
      const config = {
        headers: { "Content-Type": "application/json" },
        data: input,
      };
      const { data } = await axios.delete(`${import.meta.env.VITE_API_URL}/photo-gallery/customer/${this.customerId}/photos`, config);
      this.globalLog.info(`Added ${this.imageIdsSelected.length} to the gallery`);
      // Remove these from the local list
      this.imageIdsSelected.forEach(imageId => {
        const index = this.nonAdventurePhotos.indexOf(imageId);
        if (index > -1) {
          this.nonAdventurePhotos.splice(index, 1);
        }
      });
      this.showRemovePhotoModal = false;
      this.imageIdsSelected.splice(0); // This is reactive in Vue
      this.saveStatus = null;
    },
    back() {
      // Could have come from a few places
      history.back();
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.photo-div {
  position: relative;
  display: flex;

  //Style moved to main.scss
}

.select-photo {
  position: absolute;
  bottom: 18px;
  right: 10px;
  cursor: pointer;
  z-index: 30;
  padding: 4px 4px 0px 4px !important;
  margin-bottom: 0 !important;
}

.ui.three.cards {
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

img {
  cursor: pointer;
}

.selected-image {
  img {
    border: 4px solid $brand-color;
  }
}

.main-wrapper {
  .adventure-name-wrapper {
    justify-content: space-between;
    display: flex;
    .ui.checkbox {
      margin-left: 0.5rem;
      flex-shrink: 0;
    }
  }
}
</style>
