import { MarkerClusterer } from "@googlemaps/markerclusterer";

export const MapHelper = {
  zoomLevelWithGeolocation: 13,
  zoomLevelLocationUnknown: 8, // More zoomed out
  // Rochester
  defaultLatitude: 44.1473,
  defaultLongitude: -92.3814,

  getDefaults() {
    return {
      latitude: this.defaultLatitude,
      longitude: this.defaultLongitude,
      zoomLevel: this.zoomLevelLocationUnknown,
    };
  },

  hasLocation() {
    if (this.latitude) return true;
    else return false;
  },

  getLocation(callback) {
    const self = this;
    // If we don't have coordinates yet, go get them.
    if (!this.latitude) {
      // If they enable location use that, otherwise fallback to default from config
      if (navigator.geolocation) {
        console.info("geolocation enabled");
        navigator.geolocation.getCurrentPosition(
          function (position) {
            self.setLocation(position.coords.latitude, position.coords.longitude, self.zoomLevelWithGeolocation);
            callback(self.latitude, self.longitude, self.zoomLevel);
          },
          function (error) {
            console.warn(`goelocation error: ${error}`);
            // if (error.code == error.PERMISSION_DENIED) {
            self.setLocation(self.defaultLatitude, self.defaultLongitude, self.zoomLevelLocationUnknown);
            callback(self.latitude, self.longitude, self.zoomLevel);
          }
        );
      } else {
        console.info("geolocation disabled");
        self.setLocation(self.defaultLatitude, self.defaultLongitude, self.zoomLevelLocationUnknown);
        callback(self.latitude, self.longitude, self.zoomLevel);
      }
    } else {
      console.info("location already set");
      // Callback with the specified location
      callback(self.latitude, self.longitude, self.zoomLevel);
    }
  },
  setLocation(latitude, longitude, zoomLevel) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.zoomLevel = zoomLevel;
  },
  setZoomlevel(zoom) {
    this.zoomLevel = zoom;
  },
};

export function setMarkerClusters(map, markers, onClusterClick, icon, algorithmOptions, fontColor) {
  // algorithmOptions: { maxZoom?: number; minPoints?: number }
  const clusterParams = {
    map,
    markers,
    renderer: {
      render: ({ markers, position }) => {
        // here is where you return a Marker
        // and style it w/custom label/icon props

        const _icon = { ...icon };
        if (!_icon.url) {
          // Old icon: "https://cdn.shrpa.com/images/misc/mapicons/singledark50.png",
          _icon.url = "https://cdn.shrpa.com/images/misc/mapicons/red-circle-small.png";
        } else if (!_icon.scaledSize) {
          _icon.scaledSize = new globalThis.google.maps.Size(50, 50);
        }

        return new globalThis.google.maps.Marker({
          position: {
            lat: position.lat(),
            lng: position.lng(),
          },
          icon: _icon,
          label: { text: String(markers.length), color: fontColor ? fontColor : "white", fontSize: "15px", fontWeight: "bold", fontFamily: "quicksand" },
        });
      },
    },
  };

  if (onClusterClick) {
    clusterParams.onClusterClick = onClusterClick;
  }
  if (algorithmOptions) {
    clusterParams.algorithmOptions = algorithmOptions;
  }

  const markerClusterer = new MarkerClusterer(clusterParams);

  return markerClusterer;
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function distanceInMilesBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  if ([lat1, lon1, lat2, lon2].includes(null) || [lat1, lon1, lat2, lon2].includes(undefined)) {
    console.warn(`distanceInMilesBetweenEarthCoordinates(): Some of the lat/lon values is null or undefined`);
    return -1;
  }
  const earthRadiusMiles = 3958.8;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusMiles * c;
}
