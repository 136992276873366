import { nextTick, onMounted, onBeforeUnmount, Ref } from "vue";

export default function useSwipes(targetContainer: HTMLElement | null, swipeLeftCallback: Function, swipeRightCallback: Function, isToggleListenersAutomatically = true) {
  function addTouchListeners(_targetContainer?: HTMLElement): void {
    (_targetContainer || targetContainer)?.addEventListener("touchstart", handleTouchStart);
    (_targetContainer || targetContainer)?.addEventListener("touchend", handleTouchEnd);
    (_targetContainer || targetContainer)?.addEventListener("touchmove", handleTouchMove);
  }
  function removeTouchListeners(_targetContainer?: HTMLElement): void {
    (_targetContainer || targetContainer)?.removeEventListener("touchstart", handleTouchStart);
    (_targetContainer || targetContainer)?.removeEventListener("touchend", handleTouchEnd);
    (_targetContainer || targetContainer)?.removeEventListener("touchmove", handleTouchMove);
  }

  onMounted(async (): Promise<void> => {
    if (isToggleListenersAutomatically) {
      nextTick(addTouchListeners);
    }
  });

  onBeforeUnmount((): void => {
    if (isToggleListenersAutomatically) {
      removeTouchListeners();
    }
  });

  let isTouchMoveInProgress: boolean = false;

  let touchHorizontalDistance: number = 0;
  let touchHorizontalStartPoint = null;

  let touchVerticalDistance: number = 0;
  let touchVerticalStartPoint = null;

  function handleTouchStart(event): void {
    isTouchMoveInProgress = true;
    resetStartPoint(event);
  }

  function resetStartPoint(event): void {
    touchHorizontalStartPoint = Math.round(event.changedTouches[0].pageX);
    touchVerticalStartPoint = Math.round(event.changedTouches[0].pageY);
  }

  function handleTouchEnd(): void {
    isTouchMoveInProgress = false;

    touchHorizontalStartPoint = null;
    touchHorizontalDistance = 0;

    touchVerticalStartPoint = null;
    touchVerticalDistance = 0;
  }

  function handleTouchMove(event): void {
    if (!isTouchMoveInProgress) {
      return;
    }
    touchHorizontalDistance = -(Math.round(touchHorizontalStartPoint) - Math.round(event.changedTouches[0].pageX));
    touchVerticalDistance = -(Math.round(touchVerticalStartPoint) - Math.round(event.changedTouches[0].pageY));

    const stepSize: number = 100;

    if (touchHorizontalDistance >= stepSize) {
      swipeLeftCallback();
      resetStartPoint(event);
      isTouchMoveInProgress = false;
    } else if (touchHorizontalDistance <= -stepSize) {
      swipeRightCallback();
      resetStartPoint(event);
      isTouchMoveInProgress = false;
    }
  }

  return {
    addTouchListeners,
    removeTouchListeners,
  };
}
