<template>
  <div class="photo-upload-page">
    <div v-if="isLoading" style="margin-bottom: 5em">
      <Loader></Loader>
      <h3 style="text-align: center">Loading...</h3>
    </div>
    <div v-else-if="!campaignData">
      <!--Custom "Not found" message-->
      <h3 style="margin-top: 30px">Sorry! This upload link doesn't exist.</h3>
      <div>
        If you think this is in error feel free to reach out to support@shrpa.com<br />
        Or you can return to the Destination Page<br />
        <router-link :to="{ name: 'Pages', params: { pageId: pageId } }" class="ui primary button">Destination Page</router-link>
      </div>
    </div>
    <div v-else-if="campaignData.customerIsDisabled || (campaignData.expiryDateTime && new Date(campaignData.expiryDateTime) < new Date())">
      <!--Customer disabled or campaign expired -->
      <NoteWithIcon icon="warning_2-5" color="yellow" style="max-width: 500px">
        <template #text>
          <h3>This Upload Link is no longer active</h3>
          <div>Please contact {{ campaignData.customerName }} for more information.</div>
        </template>
      </NoteWithIcon>
    </div>

    <template v-else>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <!-- Header -->
        <img
          v-if="campaignData?.customerIcon && campaignData?.customerIcon !== 'logo-placeholder.png'"
          class="community-snippet__avatar"
          :src="`${contentBaseUri}/cms/images/orgs/${campaignData?.customerIcon}`"
        />
        <div class="header photo-upload-page__header">
          <!-- Community snippet -->
          <div class="community-snippet header__community-snippet">
            <h1 class="global-h1 header__title">
              {{ campaignData?.title }}
            </h1>
          </div>
          <!-- / Community snippet -->
          <div class="header__subtitle" style="white-space: pre-wrap">{{ campaignData?.description?.trim() }}</div>
        </div>
        <!-- / Header -->

        <!-- Examples section -->
        <div v-if="!uploadComplete && campaignData?.exampleMediaIds" class="examples-section photo-upload-page__examples">
          <!--<div class="global-h4 examples-section__title">Examples of good photos &amp; videos</div>-->
          <div v-if="campaignData?.exampleMediaIds" class="examples-section__thumbnails-list">
            <SrpFileThumbnail
              class="examples-section__thumbnail"
              v-for="imageId in campaignData.exampleMediaIds"
              :key="imageId"
              :fileName="imageId"
              :isFixedAspectRatio="false"
              galleryPostfix="i"
              isEntireAreaClickable
            />
          </div>
        </div>
        <!-- / Examples section -->
      </div>

      <div v-if="!uploadComplete && campaignData?.exampleMediaIds" class="photo-upload-page__horizontal-divider">&nbsp;</div>

      <!-- Upload form -->
      <div class="upload-form photo-upload-page__upload-form">
        <template v-if="!uploadComplete">
          <UploadPhotoForm
            class="upload-form__drop-area"
            :img="images"
            :showClose="false"
            :showRemove="true"
            :autoSelect="false"
            @imageUploadedToServer="image => images.push(image)"
            @removeMediaFile="removeImageByServerId"
            :columnsNumber="{ mobile: 2, tablet: 4, 'tablet-large': 6, laptop: 7, desktop: 10, 'desktop-wide': 10 }[screenSize]"
            :accentColor="campaignData?.accentColor"
          />

          <div class="upload-form__inputs-and-textareas">
            <!-- Title & input snippet -->
            <div class="title-and-input-snippet upload-form__title-and-input-snippet">
              <div class="global-h4 title-and-input-snippet__title">Your name</div>
              <input class="global-text-input title-and-input-snippet__input" v-model="uploaderName" type="text" spellcheck="false" placeholder="Name" />
            </div>
            <!-- / Title & input snippet -->

            <!-- Title & input snippet -->
            <div class="title-and-input-snippet upload-form__title-and-input-snippet">
              <div class="global-h4 title-and-input-snippet__title">Your email</div>
              <input class="global-text-input title-and-input-snippet__input" v-model="uploaderEmail" type="email" autocomplete="email" placeholder="user@domain.com" />
            </div>
            <!-- / Title & input snippet -->

            <!-- Title & input snippet -->
            <div class="title-and-input-snippet upload-form__title-and-input-snippet upload-form__title-and-input-snippet--full-width">
              <div class="global-h4 title-and-input-snippet__title">
                Any additional information about these images &amp; videos?
                <span style="color: rgba(0, 0, 0, 0.3)">(optional)</span>
              </div>
              <textarea
                class="global-textarea title-and-input-snippet__input"
                v-model="additionalInfo"
                placeholder="Ex. Location names or context, your social media handle, etc..."
                style="height: 70px; min-height: 70px"
              />
            </div>
            <!-- / Title & input snippet -->
          </div>

          <SrpCheckbox class="upload-form__terms-and-conditions-checkbox" haloColor="white" whiteSpace="wrap">
            <template #input><input type="checkbox" v-model="acceptedTerms" /></template>
            <template #text>
              By uploading images or videos, I grant the community ({{ campaignData?.customerName }}) and Shrpa rights per the
              <span
                class="global-a global-a--dashed"
                @click="
                  $event => {
                    $event.preventDefault();
                    showTermsModal = true;
                  }
                "
                :style="{
                  color: `${campaignData?.accentColor} !important`,
                  textDecorationColor: `rgba(${String(Object.values(convertHEXToRGB(campaignData?.accentColor)))}, 0.5) !important`,
                  whiteSpace: 'nowrap',
                }"
              >
                Terms and Conditions
              </span>
            </template>
          </SrpCheckbox>

          <SrpButton
            class="upload-form__submit-btn"
            size="big"
            :isDisabled="images.length === 0 || saveMessage !== null"
            @click="upload"
            iconInset="0 auto auto auto"
            :customColor="campaignData?.accentColor"
          >
            <template v-if="saveMessage" #icon><LoadingIcon :size="31" /></template>
            Send to {{ campaignData?.customerName }}
          </SrpButton>

          <div class="validation-errors">
            <div v-for="error in validationErrors" :key="error">{{ error }}</div>
          </div>
        </template>

        <!--Post-upload Experience-->
        <div v-else style="max-width: 800px">
          <h1 class="ui header">
            <div class="content">
              Upload Complete
              <!-- <div class="sub header">Thanks for uploading {{images.length}} photos and helping to highlight your local community!</div> -->
            </div>
          </h1>
          <div class="ui info icon message" style="margin-top: 2rem; flex-direction: column; align-items: flex-start">
            <div style="display: flex; align-items: center">
              <img class="ui tiny image hands" src="https://cdn.shrpa.com/images/misc/high-five.png" style="margin-right: 1rem" />
              <div class="content">
                <div class="header">Thanks for uploading!</div>
                <p>Your {{ images.length }} photos and videos will help highlight the local community.</p>
              </div>
            </div>
            <div style="margin-top: 20px">
              <div class="columns-layout columns-layout--4-col">
                <div v-for="image in images.map(i => i.serverId)" :key="image" style="padding: 5px; position: relative" class="columns-layout__item">
                  <template v-if="isVideo(image)">
                    <VideoRender :videoId="image" :showControls="false" :showViewerOnClick="true" :customClasses="'ui fluid rounded image middle aligned centered wow fadeInUp'" />
                  </template>
                  <template v-else>
                    <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                    <a lang="en" hreflang="en" data-fancybox="gallery" :href="`${contentBaseUri}/cms/images/expeditions/${image}`"
                      ><img class="ui fluid rounded image middle aligned centered wow fadeInUp" :src="`${contentBaseUri}/cms/images/expeditions/thumbnocrop/${image}`"
                    /></a>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="ui mini icon message">
            <i class="envelope icon"></i>
            <div class="content">
              <p>We'll send a confirmation email to the email address you provided.</p>
            </div>
          </div>
          <div class="row">
            <SrpButton :to="{ name: 'Pages', params: { pageId: pageId } }" iconInset="2px auto auto auto">
              View Community's Page
              <template #iconRight>
                <IconEmbedded name="arrow-right_3" color="rgba(255, 255, 255, 1)" :size="26" />
              </template>
            </SrpButton>
          </div>
        </div>
      </div>
      <!-- / Upload form -->

      <div style="min-height: 30px"></div>
    </template>

    <!--Terms Modal-->
    <SrpModal v-model:isVisible="showTermsModal" :isClosable="true">
      <template #content>
        <h2 class="global-h2" style="margin-bottom: 10px">Terms and Conditions</h2>

        <p class="global-p" style="margin-bottom: 8px">
          By uploading photos or videos you confirm that you have the rights to do so.<br />
          You also consent for these images and videos to be used by the community and Shrpa.<br />
          Shrpa's full Terms are here:
          <RouterLink class="global-a" lang="en" hreflang="en" target="_blank" :to="{ name: 'Terms' }">Terms and Conditions</RouterLink>&nbsp;<IconEmbedded
            name="external-link_2-5"
            :size="14"
            color="rgba(91, 91, 91, 0.5)"
            style="position: relative; top: 2px"
          />.
        </p>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import UploadPhotoForm from "../CMS/UploadPhotoForm.vue";
import FileUtils from "@logic/FileUtils";
import VideoRender from "@components/VideoRender.vue";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import { useHead } from "@unhead/vue";
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import { ScreenSize } from "@contracts/screenSize";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import { convertHEXToRGB } from "@logic/Color";
import { MetricSender } from "@/helpers/MetricSender";

interface UserUploadCampaignGetResponse {
  accentColor: string | null;

  customerId: string;
  customerName: string;
  customerIcon: string;
  customerIsDisabled: boolean;
  campaignId: string;

  title: string;
  description: string;
  isTheDefault: boolean;
  expiryDateTime: string | null;
  deletedDateTime: string | null;

  exampleMediaIds: Array<string> | null;
}

export default defineComponent({
  name: "NonUserPhotoUpload",

  components: {
    LoadingIcon,
    SrpFileThumbnail,
    IconEmbedded,
    SrpButton,
    SrpCheckbox,
    AvatarWithFallback,
    SrpModal,
    Loader,
    UploadPhotoForm,
    VideoRender,
    CopyText,
    NoteWithIcon,
  },

  data() {
    return {
      title: "Upload Photos",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      screenSize: inject("screenSize") as ScreenSize,

      pageId: null as string | null,
      campaignId: null as string | null,
      campaignData: null as UserUploadCampaignGetResponse | null,
      isLoading: true,
      saveMessage: null,
      validationErrors: [] as Array<string>,
      uploadComplete: false,

      uploaderName: null,
      uploaderEmail: null,
      additionalInfo: null,
      acceptedTerms: false,
      images: [],

      showTermsModal: false,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    // @ts-ignore
    this.campaignId = this.$route.params.uploadIdentifier;
    await this.loadData();

    MetricSender.sendMetric("CommunityUser-ViewCommunityUpload", null);
  },

  methods: {
    convertHEXToRGB,
    async loadData() {
      this.isLoading = true;
      const { data } = await axios.get<UserUploadCampaignGetResponse>(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign/${this.campaignId.trim()}`);
      this.campaignData = data;

      this.isLoading = false;
    },
    clearValidationErrors(delaySeconds: number) {
      setTimeout(() => {
        this.validationErrors = [];
      }, delaySeconds * 1000);
    },
    validate(): boolean {
      this.validationErrors = [];
      if (this.images.length === 0) {
        this.validationErrors.push("Please upload some content!");
        this.clearValidationErrors(5);
        return false;
      }
      if (this.images.some(i => !i.finishedUploading)) {
        this.validationErrors.push("Content is still uploading...");
        this.clearValidationErrors(5);
        return false;
      }

      if (!this.acceptedTerms) this.validationErrors.push("Please accept the Terms before uploading.");
      if (this.uploaderName === null || this.uploaderName.trim().length === 0 || this.uploaderEmail === null || this.uploaderEmail.trim().length === 0)
        this.validationErrors.push("Please enter a name and email before uploading.");
      if (this.uploaderEmail && (!this.uploaderEmail.includes("@") || !this.uploaderEmail.includes("."))) this.validationErrors.push("Please enter a valid email.");

      return this.validationErrors.length === 0;
    },
    removeImageByServerId(serverId: string) {
      this.images = this.images.filter(i => i.serverId !== serverId);
    },
    async upload() {
      if (!this.validate()) return;

      this.saveMessage = "Uploading...";
      const config = { headers: { "Content-Type": "application/json" } };
      let uri = `${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign/${this.campaignId}`;
      let payload = {
        uploadersName: this.uploaderName.trim(),
        uploadersEmail: this.uploaderEmail.trim(),
        additionalInfo: this.additionalInfo?.trim() || null,
        photos: this.images.map(i => i.serverId),
      };
      await axios.post(uri, JSON.stringify(payload), config);
      this.saveMessage = "Uploaded!";

      this.uploadComplete = true;
    },
    isVideo(assetName: string) {
      return FileUtils.isVideoFileType(assetName, null);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.validation-errors {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.save-message {
  display: inline;
  margin-left: 10px;
}

// Community snippet ==========================================================
.community-snippet {
  &__avatar {
    max-width: 250px;
    max-height: 150px;
    margin-bottom: 10px;
  }

  &__name {
    color: rgba(91, 91, 91, 1);
    font-weight: 500;
  }
}

// Header =====================================================================
.header {
  &__community-snippet {
    margin-bottom: 10px;
  }

  &__title {
    text-align: center;
    margin-bottom: 4px;
  }

  &__subtitle {
    max-width: 750px;
  }
}

// Examples section ===========================================================
$thumbnailHeight: 80px;
$thumbnailsGap: 12px;

.examples-section {
  &__title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__thumbnails-list {
    max-height: calc(#{$thumbnailHeight} * 1);
    gap: $thumbnailsGap;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
  }

  &__thumbnail {
    height: $thumbnailHeight;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  $thumbnailHeight: 60px;
  $thumbnailsGap: 6px;

  .examples-section {
    &__thumbnails-list {
      max-height: calc(#{$thumbnailHeight} * 1);
      gap: $thumbnailsGap;
    }

    &__thumbnail {
      height: $thumbnailHeight;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  $thumbnailHeight: 60px;
  $thumbnailsGap: 6px;

  .examples-section {
    &__thumbnails-list {
      max-height: calc(#{$thumbnailHeight} * 2 + #{$thumbnailsGap} * 2);
      gap: $thumbnailsGap;
    }

    &__thumbnail {
      height: $thumbnailHeight;
    }
  }
}

// Title & input snippet ======================================================
.title-and-input-snippet {
  &__title {
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__input {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .title-and-input-snippet {
    &__title {
      margin-bottom: 3px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .title-and-input-snippet {
    &__title {
      margin-bottom: 3px;
    }
  }
}

// Upload form ================================================================
.upload-form {
  padding: 25px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(243, 243, 243, 1);

  &__drop-area {
    flex-grow: 1;
    width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 38px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
  }

  &__inputs-and-textareas {
    gap: 20px 35px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__title-and-input-snippet {
    width: calc(50% - 19px);
    max-width: 420px;

    &--full-width {
      width: 100%;
      max-width: 1200px;
    }
  }

  &__terms-and-conditions-checkbox {
    margin-bottom: 17px !important;
  }

  &__submit-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .upload-form {
    padding: 17px 15px;

    &__title-and-input-snippet {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .upload-form {
    padding: 12px 11px;

    &__title-and-input-snippet {
      width: 100%;
    }
  }
}

// Photo upload page ==========================================================
.photo-upload-page {
  width: calc(100% - 60px);
  max-width: 1600px;
  height: 100dvh;
  box-sizing: border-box;
  padding: 25px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__header {
    margin-bottom: 22px;
  }

  &__horizontal-divider {
    width: 100%;
    height: 0;
    margin-bottom: 19px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__examples {
    margin-bottom: 30px;
  }

  &__upload-form {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .photo-upload-page {
    width: calc(100% - 40px);
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .photo-upload-page {
    width: calc(100% - 20px);
  }
}
</style>
